.workspaces {
  position: absolute;
  bottom: 0;
  padding: 12px 24px;
  width: 100%;

  .dropdown {
    width: 100%;

    .dropdown-button {
      color: var(--sidebar-text-color);
      padding: 8px 16px;
      border-radius: 6px;
      width: 100%;

      span {
        color: var(--sidebar-text-color);
      }

      &:hover,
      &--open {
        background-color: var(--sidebar-active-bg);
      }
    }

    .dropdown-menu {
      background-color: white;
      width: 100%;

      a,
      p {
        margin: 0;
        color: var(--sidebar-workspace-color);
      }

      span {
        color: inherit;
      }

      a:hover {
        text-decoration: none;
        color: var(--interactive-hover);
      }
    }
  }
}
