.upload-media-button {
  display: none;
}

@media only screen and (max-width: 414px) {
  .upload-media-button {
    display: block;
    position: fixed;
    bottom: 14px;
    left: 80px;
    transform: translateX(-50%);

    z-index: 50;

    &--hide {
      display: none;
    }
  }
}
