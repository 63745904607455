.card.blank-billing-card {
  border: 1px dashed var(--text-primary);
  box-sizing: border-box;
  border-radius: 8px;

  .card-body {
    padding: 78px 46px;
    text-align: center;

    p {
      margin: 0;
    }
  }
}
