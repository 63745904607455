.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  height: 100vh;
}

.company-container {
  display: flex;
  flex-direction: column;
  padding: 20px 44px;
  height: 100%;

  background-color: var(--primary-800);
  text-align: center;

  .logo {
    display: flex;
    justify-content: flex-start;
    padding: 8px;
  }

  span {
    letter-spacing: -0.02em;
    color: white;
    margin: 0;
  }

  .content-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin: 0 auto;
    max-width: 510px;

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 6px;

      .title {
        font-weight: 800;
        font-size: 48px;
        line-height: 56px;
      }

      .sub-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  .features-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 24px;

    border: 2px solid #ffffff;
    border-radius: 10px;

    span {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 5px;
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      color: white;
      margin: 0;
      padding-left: 12px;
      text-align: left;
    }
  }

  background-image: url(../assets/images/signup-illustration.svg);
  background-repeat: no-repeat;
  background-position: 5% 112%;
  background-size: 260px 215px;
}

.form-container {
  display: flex;
  flex-direction: column;

  .form {
    max-width: 384px;
    margin: auto;
  }

  //Only for mobile
  .subly-logo {
    display: none;
  }

  .features-mobile {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .company-container {
    .content-main {
      margin: auto;
    }
    background-image: none;
  }

  .form-container {
    .form {
      padding: 50px;
    }
  }
}

@media only screen and (min-width: 1550px) {
  .company-container {
    .content-main {
      margin: auto;
    }
  }
}

@media only screen and (max-width: 768px) {
  .company-container {
    padding: 10px;
  }

  .form-container {
    .form {
      padding: 50px;
    }
  }
}

@media only screen and (max-width: 425px) {
  .container {
    display: flex;
    justify-content: center;
  }

  .company-container {
    display: none;
  }

  .form-container {
    .form {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 20px;
    }

    .subly-logo {
      display: block;
      align-items: center;
    }

    .features-mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 24px;
      width: 100%;
      margin-top: 10px;

      background: var(--neutral-50);

      border: 2px solid var(--neutral-200);
      border-radius: 10px;

      span {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: var(--neutral-700);
      }

      ul {
        padding-left: 12px;
        margin: 0;
        li {
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}
