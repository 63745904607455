.pricing-toggle {
  border: none;
  border-radius: 4px;
  margin-right: 1rem;
  button {
    background-color: transparent;
    color: var(--text-secondary);
    border: var(--text-disabled) 1px solid !important;
    border-radius: 4px;
  }

  .selected {
    background-color: var(--interactive);
    color: white;
    border: var(--interactive-hover) 1px solid !important;
  }
}
