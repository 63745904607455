.modal {
  position: fixed;
  top: auto;
  bottom: 72px;
  right: 32px;
  box-shadow: 0px 0px 10px #cad7dc;
  border-radius: 60px;
  padding: 4px 24px;
  max-width: none;
  width: auto;

  p,
  a {
    text-align: center;
  }
}
