.h3 {
  margin-bottom: 0px !important;
}

.settings {
  .col-settings {
    width: 100%;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
    background-color: white;
  }

  .settings-navbar {
    a {
      color: var(--interactive);
      font-size: 14px;

      &:hover {
        color: var(--interactive);
        background-color: white;
      }

      &.active {
        background-color: white;
        font-weight: 500;
        border-bottom: 2px solid var(--interactive);

        &:hover {
          background-color: white;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .settings {
    .col-settings {
      max-width: 235px;
      filter: none;
    }

    .settings-navbar {
      a {
        color: var(--text-secondary);
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;

        &:hover {
          color: var(--interactive-hover);
          background-color: #ead4ff;
        }

        &.active {
          background-color: var(--interactive-background);
          border: none;
          color: var(--interactive-hover);

          &:hover {
            background-color: #ead4ff;
          }
        }
      }
    }
  }
}

@media (min-width: 900px) {
  .settings {
    .col-settings {
      margin-right: 150px;
    }
  }
}

@media (max-width: 576px) {
  .settings {
    .full-height {
      min-height: auto;
    }
  }
}
