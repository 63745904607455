.container {
  width: 100%;
  border: 1px solid var(--tertiary);
  border-radius: 5px;
}

.button {
  border: 2px solid transparent !important;
  border-radius: 4px 0px 0px 4px !important;

  &--right {
    border-radius: 0px 4px 4px 0px !important;
    margin-left: 0px !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.selected {
  background: var(--interactive-background) !important;
  border: 2px solid var(--interactive) !important;
  color: var(--interactive) !important;
}
