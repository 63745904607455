.modal {
  width: 100%;
  max-width: 950px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  top: 10px;
}

.header {
  text-align: center;
  margin-bottom: 6px;

  h5 {
    margin-bottom: 0.5rem;
  }
}

.link {
  max-width: 350px;
  width: 100%;
  margin-top: 24px;
  align-self: flex-end;
}

.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.card {
  display: flex;
  flex-direction: column;
  background-color: #f8f7f7;
  padding: 1.8rem 1.2rem 1rem 1.2rem;
  border-radius: 30px;
  margin-bottom: 5px;
  width: 278px;
  height: 525px;

  .plan {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;

    img {
      width: auto;
      margin-right: 0.5em;
    }

    .plan-name {
      display: flex;
      flex-direction: column;
    }

    .subly {
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
      color: var(--text-primary);
      margin-bottom: 4px;
    }
  }

  .price-container {
    .dollar-sign {
      font-size: 16px;
      position: relative;
      top: -1em;
      color: var(--text-primary);
    }

    .price {
      font-weight: 600;
      font-size: 34px;
      color: var(--interactive);
    }

    .price-monthly {
      margin-left: 1px;
      font-size: 16px;
      color: var(--text-primary);
    }
  }

  .business-feature {
    &--desc {
      margin-bottom: 2px;
      font-size: 12px;
      line-height: 12px;
      color: var(--text-primary);
    }

    &--link {
      font-size: 12px;
      a {
        color: var(--interactive);
      }
    }
  }

  .plan-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;

    .button {
      box-sizing: border-box;
      background-color: var(--accent-4);
      border: 2px solid var(--accent-4);
      border-radius: 50px;
      box-shadow: none;
      color: white;
      cursor: pointer;
      transition: all 0.2s;

      &:active {
        outline: 0;
      }

      &:hover {
        background-color: var(--interactive-hover);
        border-color: var(--interactive-hover);
        outline: 0;
        color: white;
      }
    }
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  background-color: #0000000d;
  padding: 12px 14px;
  border-radius: 12px;

  .title {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: var(--text-primary);
    font-weight: bold;
    margin-bottom: 3px;
  }

  .box {
    align-items: center;
    column-gap: 0;
    display: grid;
    flex: 0 0 auto;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.plan-list {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  padding-left: 0;
  row-gap: 2px;
  margin-bottom: 10px;

  &-title {
    color: var(--text-primary);
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 2px;
  }

  .item {
    color: var(--text-primary);
    display: flex;
    font-size: 12px;
    line-height: 24px;

    .icon {
      color: var(--accent-2);
      margin-right: 10px;
      position: relative;
      top: 4px;
      width: 14px;
      height: 14px;
    }
  }
}

.feature-mobile {
  display: none;
}

.toggle-margin {
  margin-bottom: -50px;
}

@media only screen and (max-width: 768px) {
  .modal {
    top: 2vh;
  }

  .card-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 375px) {
  .modal {
    top: 2vh;
  }

  .feature-mobile {
    display: contents;
  }

  .feature {
    display: none;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .card {
    max-height: 400px;
    height: 100%;
    margin-bottom: 1em;
  }
}
