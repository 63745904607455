.container {
  margin-bottom: 30px;
}

.drop-zone {
  padding-top: calc(56% + 69.2px); // 69.2 to account for media card bottom
}

.drop-zone-active {
  padding-top: calc(56% + 67.2px); // .drop-zone height - the extra border
}

.card {
  display: flex;
  flex-direction: column;
  background-color: white;
  transition: all 0.2s;
  justify-content: center;
  align-items: center;
  border: dashed 1px var(--neutral-300);
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  line-height: 20px;
  bottom: 13px;
  left: 15px;
  position: absolute;
  right: 15px;
  top: 0;
  .button {
    margin: 0 25px;
  }
}

@media only screen and (max-width: 414px) {
  .drop-zone {
    padding-top: 0;
  }

  .drop-zone-active {
    padding-top: 0;
  }
  .card {
    display: none;
  }
}
