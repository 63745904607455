.container {
  padding: 8px 16px;
  display: block;
}

.shortcutsContainer {
  background-color: var(--neutral-50);
  border-radius: 10px;
  border: 1px solid var(--neutral-100);
  overflow-x: auto;
}

.shortcuts {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 12px;
  gap: 12px;
}

.shortcut {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .name {
    color: var(--neutral-900);
    margin: 4px 0;
    transition: all 300ms;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: inline;
    margin-right: 5px;
  }
  .badges {
    display: inline;
    border-radius: 100px;
    color: white;

    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.25px;

    padding: 3px 6px;

    overflow: hidden;
    white-space: nowrap;

    flex-shrink: 0;
    background: var(--accent-1);
  }

  &:hover .name {
    color: var(--primary-500);
  }

  svg {
    border: 1px solid var(--neutral-200);
    border-radius: 12px;
  }
}
