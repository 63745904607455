.chevron {
  display: none;
}

.close-icon {
  color: var(--neutral-700);
  top: 32px;
  right: 30px;
}

.header-text {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.searchbar {
  input {
    width: 100%;
  }
}

.icon {
  height: 20px;
  width: 20px;
  color: rgba(107, 114, 128, 1);
}

@media only screen and (max-width: 414px) {
  .modal {
    position: fixed !important;
    bottom: auto;
    top: 0;
    max-width: 100vw;
    height: 100vh;

    h5 {
      margin-bottom: 0;
      margin-left: 6px;
    }

    .chevron {
      display: inline;
    }
  }

  .modal-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .icon {
      cursor: pointer;
      padding-bottom: 2px;
    }
  }

  .close-icon {
    display: none;
  }
}
