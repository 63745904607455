.cookies-consent {
  position: fixed;
  bottom: 20px;
  right: 20px;
  left: 20px;
  background-color: #111111;
  height: auto !important;
  max-width: 460px;
  padding: 20px;
  border-radius: 5px;
  z-index: 9999;

  p {
    color: white;
  }

  a {
    font-size: inherit;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) {
  .cookies-consent {
    width: 460px;
    bottom: 20px;
    right: 20px;
    left: auto;
  }
}

@media only screen and (max-width: 420px) {
  .actions {
    flex-direction: column;

    button {
      display: block;
      width: 100%;
      margin-bottom: 8px;
    }
  }
}
