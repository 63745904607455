.google-button {
  display: block;

  &:hover {
    text-decoration: none;
  }

  .customGPlusSignIn {
    display: inline-block;
    background: white;
    color: #444;
    display: flex;
    border-radius: 5px;
    border: thin solid lightgray;
    white-space: nowrap;
    align-items: center;
    cursor: pointer;

    .icon {
      background: url("../../assets/images/g-normal.png") transparent 5px 50%
        no-repeat;
      display: inline-block;
      vertical-align: middle;
      width: 42px;
      height: 42px;
      flex-grow: 0;
    }

    .label {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      flex-grow: 1;
      text-align: center;
      margin-right: 42px;
    }
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.2;
  }
}
