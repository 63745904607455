.plan-card {
  background: var(--neutral-100);
  border-radius: 8px;
  padding: 12px;

  .plan {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .free {
      color: var(--text-primary);
      margin: 0 0 0 5px;
    }
  }

  .info {
    margin-top: 10px;

    .date {
      margin: 3px 0;
      letter-spacing: 0.15px;
      font-size: 14px;
      color: var(--text-primary);
    }

    .cancel {
      margin: 0;

      a {
        font-size: 14px;
        color: var(--text-primary);
        text-decoration-line: underline;
      }
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .logo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .balance {
    display: flex;
    align-items: flex-end;
  }

  .text {
    font-weight: 500;
    color: var(--neutral-500);
  }

  .credit {
    font-weight: 600;
    color: var(--neutral-900);

    &-warning {
      color: var(--warning-700);
    }
  }
}

.divider {
  background-color: var(--neutral-300);
  margin: 10px 0;
}

.plan-info {
  display: flex;
  flex-direction: column;
  margin: 12px 0;

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral-900);
  }

  .data {
    color: var(--neutral-900);
    margin-bottom: 0;

    &-warning {
      color: var(--warning-700);
    }
  }
}

.sub-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: var(--neutral-900);
}

.total-box {
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral-900);
  }

  .quantity {
    font-weight: 600;
  }
}

.balance {
  margin-bottom: 0;

  span {
    color: var(--neutral-700);
    letter-spacing: 0.15px;
    font-size: 14px;
    font-weight: 500;
  }

  span:nth-child(2) {
    color: var(--neutral-900);
    font-weight: 600;
  }
}

.plan-card-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;

  a {
    margin-top: -6px !important;
  }

  .features {
    text-align: right;
    font-size: 14px;
    color: var(--text-primary);
    margin-bottom: 5px;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.badge {
  background-color: var(--brand);
  color: var(--text-primary);
  padding: 5px;
  border-radius: 4px;
  margin-left: 2px;
}

.trial-badge {
  color: var(--interactive);
  background: #f2e5ff;
  border-radius: 38px;
  padding: 5px;
  margin-left: 2px;
}

.payg-text {
  color: var(--text-primary);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;
  margin-top: 1.5rem;
}

.top-up-box {
  margin: 5px 0;
}

.top-up-input {
  border: 1px var(--neutral-300) solid !important;
  border-radius: 6px !important;
  padding-left: 40px !important;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  margin: 2px 0;

  font-weight: 400 !important;
  font-size: 14px !important;
  color: var(--neutral-900) !important;

  &:focus {
    border: 1px solid #b794fa !important;
  }
}
.stopwatchIcon {
  position: absolute;
  left: 12px;
  color: var(--neutral-500);
  width: 16px;
  height: 16px;

  path {
    stroke-width: 1.67;
  }
}

.top-up-label {
  &-left {
    display: block;
    font-size: 14px;
    color: var(--neutral-900);
    text-align: left;
  }

  &-right {
    display: block;
    font-size: 12px;
    line-height: 20px;
    color: var(--neutral-700);
    text-align: right;
  }
}

.change-plans {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--neutral-900);

  display: flex;
  flex-direction: column;

  .plans-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .plan-card-actions {
    display: flex;
    flex-direction: column;

    a {
      display: inline-block;
      margin-top: 12px !important;
      margin-left: 0 !important;
    }
  }
}

@media only screen and (max-width: 425px) {
  .header {
    .logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
    }
  }

  .plan-card-actions {
    display: flex;
    flex-direction: column;

    a {
      display: inline-block;
      margin-top: 12px !important;
      margin-left: 0 !important;
    }
  }

  .top-up-label {
    &-right {
      font-size: 10px;
    }
  }
}
