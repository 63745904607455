.search-bar-container {
  position: relative;

  input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px 10px 35px;

    width: 370px;
    height: 40px;

    background: #ffffff;

    border: 1px solid #d1d5db;

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
  }
}

.icon {
  top: 7px;
  left: 7px;
  height: 22px;
  width: 22px;
  position: absolute;
  color: var(--neutral-500);
}
