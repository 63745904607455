.container {
  position: relative;
  cursor: not-allowed;
}

.lock {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.6);
}
