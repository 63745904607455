.table-header {
  border-top: none !important;
}

.profile-cell {
  width: 46px !important;
  height: auto;
  vertical-align: middle !important;
  border-bottom: 2px solid #dee2e6;
}

.cell {
  vertical-align: middle !important;
  border-bottom: 2px solid #dee2e6;
}