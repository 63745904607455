.media-card {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral-200);
  border-radius: 10px;
  background-color: white;
  margin-bottom: 30px;
  cursor: not-allowed;
  transition: all 0.2s;

  &.is-ready {
    cursor: pointer;
  }

  &.is-error {
    height: calc(100% - 30px);
  }

  &:hover,
  &.is-dropdown-open {
    margin-top: -4px;
    filter: drop-shadow(0px 2px 22px rgba(0, 0, 0, 0.3));
    overflow: visible;

    a {
      text-decoration: none;
      cursor: inherit;
    }
  }
}

.profile {
  display: flex;
  justify-content: flex-end;
  z-index: 4;
  right: 0;
  transform: translateX(18px) translateY(10px);
  margin-top: -32px;
}

.tooltip {
  width: 231px;
  text-align: center;
}

.figure {
  padding-top: 56%;
  display: block;
  position: relative;
  background-color: var(--light);
  border-radius: 10px;

  img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &.error {
    padding-top: 0;
    height: 100%;
  }
}

.toolbar {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  z-index: 3;
  padding: 10px;

  background: rgba(30, 30, 30, 0.6);

  border-radius: 10px 10px 0 0;
  align-items: center;
  justify-content: center;

  display: none;

  &--progress {
    display: flex;
    background: rgba(255, 255, 255, 0.8);
    flex-direction: column;
  }

  &--error {
    position: static;
    display: flex;
    padding: 16px;
    flex-direction: column;
    background-color: var(--feedback-error-background);
    height: 100%;
    justify-content: space-between;
  }

  &.toolbarSelected {
    display: flex;
  }
}

.tool-container {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
}

.checkbox {
  margin-right: 8px;
  label::before {
    height: 20px;
    width: 20px;
  }
  label::after {
    height: 20px;
    width: 20px;
  }
}

// ************************
// ! This only for developing the new editor
.use-new-editor {
  position: absolute;
  left: 5px;
  bottom: 5px;
  display: flex;

  span {
    color: white;
    // margin-left: 1rem;
  }
}
// ***********************

.media-card-dropdown {
  margin-top: 3px;
}

.error-message {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
}

.media-card .selected-card-top {
  display: flex;
}

.media-card:hover .toolbar {
  display: flex;
}

.edit-button {
  background-color: transparent;
  border-color: white;
  color: white;
  padding: 8px 18px;

  &.pick-button {
    display: none;
  }
}

.error-delete {
  cursor: pointer !important;
}

.report-button {
  cursor: pointer !important;
  background-color: transparent;
}

@media only screen and (max-width: 575px) {
  .media-card {
    &:hover,
    &.is-dropdown-open {
      margin-top: 0;
    }
  }

  .edit-button.pick-button {
    display: block;
  }
}

.badge-container {
  position: absolute;
  top: 11px;
  left: 12px;
}

@mixin badge {
  width: 100%;
  height: 26px;
  position: relative;
  margin-bottom: 8px;
  background-color: white;
  display: flex;
  align-items: center;
  background: var(--neutral-50);
  z-index: 3;
  flex-direction: row;
  padding: 0;
  box-sizing: border-box;
  border-radius: 4px;

  .icon {
    width: 50%;
    height: 100%;
    flex-basis: 100%;
    background-color: var(--interactive);
    color: white;
    display: flex;
    align-content: stretch;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 4px 0 0 4px;
    border: 1px solid var(--primary-600);
    border-right: 0;
  }

  .text {
    flex-basis: 100%;
    padding: 3px 8px;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-900);
    border-radius: 0 4px 4px 0;
    border: 1px solid var(--primary-600);
    border-left: 0;
  }
}

.language-badge {
  @include badge;
}

.snippet-badge {
  @include badge;

  .icon {
    background-color: var(--tertiary-500);
    border-color: var(--tertiary-700);
  }

  .text {
    border-color: var(--tertiary-700);
  }
}

.media-card-bottom {
  padding: 9px 15px;

  .name {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }

  .info {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--neutral-500);
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .tool-container {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;

    .checkbox {
      margin-right: 0;
    }

    .media-card-dropdown {
      margin-top: 5px;
    }
  }
}

@media only screen and (min-width: 1980px) {
  .error-message {
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.15px;
  }
}
