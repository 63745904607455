.dropdown-button {
  border: 1px solid transparent;
  border-radius: 4px;
  user-select: none;

  &:hover {
    border: 1px solid var(--border);
  }
}

.dropdown-menu {
  width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.item {
  color: var(--dark);
  padding: 5px 20px;

  span {
    color: var(--dark);
  }

  &:active {
    p,
    h6,
    span {
      color: white;
    }
  }

  &--notification {
    &:active {
      p {
        color: var(--dark);
      }
    }
  }
}

.divider {
  margin-left: 20px;
  margin-right: 20px;
}
