.modal {
  top: 2vh;
  max-width: 550px;
  overflow-y: auto;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-900);
  margin-bottom: 0;
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  margin: 20px 0;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    color: var(--neutral-700);
  }
}

.cta-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  column-gap: 12px;
}

.storage-info {
  display: flex;
  flex-direction: row;
  padding: 2px 8px;
  gap: 24px;

  margin: 8px 0;

  background: var(--neutral-50);
  border: 1px solid var(--neutral-100);
  border-radius: 10px;

  .balance {
    margin-bottom: 0;

    span {
      color: var(--neutral-600);
      font-size: 12px;
      font-weight: 500;
    }

    span:nth-child(2) {
      font-weight: 600;
      color: var(--neutral-900);
    }
  }
}

.selection-box {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 12px;

  margin: 10px 0;

  background: var(--neutral-50);
  border: 1px solid var(--neutral-100);
  border-radius: 10px;

  &-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral-900);
    margin-bottom: 0;

    span {
      font-size: 12px;
      line-height: 20px;
      color: var(--neutral-700);
    }
  }
}

@media only screen and (max-width: 425px) {
  .modal {
    top: 0;
  }

  .storage-info {
    .balance {
      span {
        font-size: 8px;
      }
    }
  }

  .selection-box {
    &-title {
      font-size: 10px;
      span {
        font-size: 8px;
      }
    }
  }
}
