.letter-icon {
  background-color: var(--dark);
  color: white;
  border: 1px solid var(--border);
}

.uploader-icon-holder {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  transition: all 0.2s;
  background-color: #c4c4c4;
  cursor: pointer;

  br {
    display: none;
  }

  .uploader-icon-holder-text {
    display: none;
  }
}

.profile-image {
  object-fit: cover;
}

@media (min-width: 576px) {
  .uploader-icon-holder {
    height: 100px;
    width: 100px;

    /*used to force a line break in a flex object*/
    br {
      display: block !important;
      width: 100%;
      content: "";
    }

    .uploader-icon-holder-text {
      display: block !important;
    }
  }
}
