.animate {
  animation-duration: 0.3s;
  animation-fill-mode: both;

  &.animate-border {
    transition: border 0.3s;
  }

  &.animate-slide-up {
    animation-name: slideUp;
  }

  &.animate-slide-down {
    animation-name: slideDown;
  }

  &.animate-slide-right {
    animation-name: slideRight;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0.5rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(0.5rem);
    opacity: 0;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-0.5rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(-0.5rem);
    opacity: 0;
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.spin {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
