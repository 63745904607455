.upload-zone {
  outline: none;

  &.show-upload-zone {
    border: dashed 1px var(--neutral-300);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    min-height: 222px;
  }

  &.active {
    border: dashed 2px var(--primary-400);
    border-radius: 10px;
  }

  &.error {
    background-color: var(--feedback-error-background);
    border: dashed 2px var(--destructive-500);
    border-radius: 10px;
  }

  .upload-btn {
    width: 260px;
    height: 36px;
    margin-bottom: 8px;

    .icon {
      height: 21px;
      width: 21px;
      margin-right: 8px;
    }

    &:first-child,
    &:last-child {
      border-color: var(--neutral-300);
      color: var(--neutral-700);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .caption {
    a {
      text-decoration: underline !important;
    }
  }
}

.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  text-align: center;
  color: var(--neutral-900);
}
.srt-warning {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
}

.active-container {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    margin: 0;
  }
}
