.modal {
  top: 20px;
  width: auto;
  max-width: inherit;
  overflow: auto;
  padding: 24px;
}

.form {
  max-width: 612px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.alert {
  background-color: var(--background);
  padding: 16px;
  margin: 24px 0;
  border-radius: 4px;
  display: none;

  &--show {
    display: block;
  }
}

.languageSelector {
  &--error {
    border: 1px solid var(--feedback-error) !important;
  }
}
.errorMessage {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 0 !important;
  padding-right: 15px;
  color: var(--feedback-error);
}

.upload-btn {
  max-width: 250px;
  min-height: 36px;
}

.option-btn {
  color: var(--primary-800);
  text-decoration-line: none;
  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
    color: var(--primary-800);
  }
}

.close {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  height: 22px;
  width: 22px;
  fill: var(--text-secondary);
}

.video {
  width: 100%;
  height: 393px;
}

.totals {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 8px;

  label {
    font-weight: 400;
    color: var(--text-primary);

    b {
      font-weight: 600;
    }
  }
}

.check-box {
  display: flex;
  flex-direction: column;

  span {
    font-size: 14px;
    color: var(--neutral-500);
  }
}

.checkout-container {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.upload {
  width: 600px;
}

@media only screen and (min-height: 900px) {
  .modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: initial;
  }
}

@media only screen and (max-width: 768px) {
  .modal {
    max-height: 100vh;
    overflow: auto;
  }

  .form {
    max-width: none !important;
  }

  .upload-zone {
    min-height: auto;
    margin-bottom: 24px;
  }

  .video {
    height: auto;
    max-height: 280px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
  .checkout-container {
    .upload {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 425px) {
  .checkout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .row {
    flex-direction: column;
  }

  .upload {
    max-width: 300px;
  }
}
