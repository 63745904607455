.modal {
  max-width: 600px;
  padding: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .modal {
    top: 5vh;
  }
}

.header {
  display: flex;
  align-items: center;
}

.title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;
}

.icon {
  height: 40px;
  width: 40px;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
}

.divider {
  margin: 12px 0 24px 0;
}

.badges {
  display: inline;
  border-radius: 100px;
  color: white;

  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.25px;

  padding: 3px 6px;
  margin-left: 16px;

  overflow: hidden;
  white-space: nowrap;

  flex-shrink: 0;
  background: var(--accent-1);
}

.button {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
