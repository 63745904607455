.modal {
  max-width: 485px;
  max-height: 80vh;
  overflow: auto;
}

.delete-invite {
  cursor: pointer;
}

.add-btn {
  width: 95px;
  margin-left: 9px;
}

.purple-box {
  background-color: var(--interactive-background);
  padding: 10px;
}
