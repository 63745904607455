@media only screen and (max-width: 414px) {
  .modal {
    position: absolute !important;
    top: auto;
    bottom: 0;
    max-width: 414px;
  }

  .close-icon {
    display: none;
  }
}
