.balance-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.balance-menu {
  padding: 20px !important;
  width: 400px;
}

.info-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 12px;

  background-color: var(--secondary-50);
  border-radius: 6px;
  margin-bottom: 10px;

  &--storage {
    background-color: var(--tertiary-50);
  }

  .balance {
    .data {
      font-weight: 600;
    }
  }
}

.topped-up {
  font-size: 12px;
  line-height: 20px;
  color: var(--neutral-600);
  margin: 5px 0;
}

.monthly {
  font-size: 14px;
  margin-bottom: 0;
  color: var(--neutral-900);
}

.divider {
  margin: 12px 0;
  background-color: var(--neutral-300);
}

.upgrade-button {
  background: transparent;
  margin-top: 10px;
  border: 1px solid var(--neutral-900);
  color: var(--neutral-900);
}
