.modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  z-index: 1001; // more than this value, the upload modal will conflict with the google upload modal
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  & h5 {
    margin-bottom: 1rem;
  }

  &--no-bg {
    position: static;
  }
}

.background {
  background: rgba(30, 30, 30, 0.6);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.content {
  position: relative;
  top: 50px;
  background-color: white;
  padding: 32px;
  border: 1px solid var(--border);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 100%;
  max-width: 437px;
  z-index: 50;
}

.close {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  height: 22px;
  width: 22px;
}
