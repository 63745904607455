.authContainer {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 40px;
}

.formContainer {
  background: white;
  box-shadow: 0px 25px 75px rgba(189, 189, 189, 0.34);
  border-radius: 12px 0px 0px 12px;
  display: flex;
  justify-content: center;
  padding: 60px 40px;
  min-height: 600px;
}

.customerSpotlight {
  padding: 60px 40px;
  border-radius: 0px 12px 12px 0px;
}

.dots {
  overflow: hidden;
  z-index: -1;
  top: 250px;
  right: -100px;
}

.vectorCurve {
  overflow: hidden;
  z-index: -1;
  left: -30px;
  bottom: -30px;
}

@media only screen and (max-width: 991px) {
  .authContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    max-width: 546px;
    margin: 40px auto;
  }

  .formContainer {
    border-radius: 12px 12px 0px 0px;
    min-height: auto;
  }

  .customerSpotlight {
    padding: 20px 40px;
    border-radius: 0px 0px 12px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .authContainer {
    margin: 20px auto;
  }

  .formContainer {
    padding: 10px 20px 20px;
  }

  .customerSpotlight {
    padding: 10px 20px 20px;
  }
}
