.h2 {
  border-radius: 8px;
  margin-top: 64px;
  font-weight: 400;
}

.h3 {
  margin: 0;

  span:not(.old-price) {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-secondary);
  }
}

.h4,
.h6 {
  margin: 0;
}

.p {
  margin: 0;

  &--subtitle {
    font-weight: 600;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.a {
  text-align: center;
  display: block;
  margin-top: 16px;
}

.p2 {
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 0.15px;
  color: var(--text-secondary);
}

.caption {
  text-align: center;
  line-height: 39px;
  margin: 0;
}

.features {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #1e1e1e;
}

.text-center {
  text-align: center;
}

.plans {
  margin-top: 72px;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .plans {
    padding: 0 24px;
    margin-top: 48px;
    margin-bottom: 32px;
  }
}

.cancel-schedule {
  max-width: 600px;
  justify-content: center;
}

.close {
  position: absolute;
  right: 32px;
  top: 32px;
  z-index: 1;
  cursor: pointer;
  height: 36px;
  width: 36px;
  border: 2px solid var(--tertiary);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .close {
    top: 16px;
  }
}

.popular-badge {
  position: absolute;
  top: -32px;
  left: -1px;
  width: calc(100% + 2px);
  height: 32px;
  background: #39efa5;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  text-transform: uppercase;
  padding: 7px 24px;
  text-align: center;

  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 2px;
}

.card {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 16px;

  border: 1px solid var(--neutral-300);

  h5 {
    position: relative;
    margin: 0;
    font-weight: 600;
  }

  .edit-icon {
    cursor: pointer;
    color: var(--interactive);
  }

  &-editing {
    box-shadow: 0 10px 20px -3px rgba(16, 24, 40, 0.08),
      0px 4px 15px -4px rgba(16, 24, 40, 0.1);
  }
}

@media only screen and (max-width: 768px) {
  .card {
    max-width: initial;
    margin: 0 0 32px;

    &--popular {
      margin-top: 32px;
    }
  }
}

.ul {
  list-style: none;
  padding: 0;
}

.min-height {
  min-height: 375px;
  margin-top: 32px;
}

@media only screen and (max-width: 768px) {
  .min-height {
    margin-bottom: 32px;
    min-height: initial;
  }
}

.li {
  position: relative;
  padding-left: 25px;
  margin-top: 6px;
  color: var(--text-primary);

  &::before {
    content: "";
    display: inline-block;
    height: 1em;
    width: 1em;
    background-image: url("../../../assets/images/checkmark.svg");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 5px;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.subscribe {
  flex-grow: 1;
}

.year-discount-label {
  display: inline-flex;
  position: absolute;
  top: 26px;
  right: 4px;
  background-color: var(--brand);
  font-size: 14px;
  font-weight: 500;
  padding: 2px 4px;
}

.pricing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 86px;
}

.cta {
  min-height: 70px;
}

@media only screen and (max-width: 768px) {
  .cta {
    min-height: initial;
  }
}

.coupon-btn {
  width: 100%;
}

.purchase-btn {
  width: 100%;
  margin-bottom: 24px;
}

.total-card {
  width: 320px;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 10px 20px -3px rgba(16, 24, 40, 0.08),
    0px 4px 15px -4px rgba(16, 24, 40, 0.1);

  &--payg {
    background-color: var(--interactive-hover);
  }
}

@media only screen and (max-width: 768px) {
  .total-card {
    width: auto;
  }
}

.old-price {
  text-decoration: line-through;
  color: var(--text-disabled);
  font-size: inherit;
}

.total-table {
  padding-top: 24px;
  border-top: 1px solid var(--border);

  &--payg {
    padding-top: 8px;
    border-top: 1px solid var(--border);
  }
}

.balance-row {
  padding-bottom: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &--payg {
    display: flex;
    flex-direction: column;
  }
}

.subtotal-row {
  padding-bottom: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &--payg {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &--payg > p {
    color: rgba(255, 255, 255, 0.7);
  }
}

.credit-row,
.tax-row {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-top: 1px dashed var(--border);

  &--payg > p {
    color: rgba(255, 255, 255, 0.7);
  }
}
.total-row {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-top: 1px solid var(--border);

  &--payg {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--border);
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.link {
  color: var(--interactive);
  display: flex;
  align-items: center;

  &:hover {
    color: var(--interactive);
  }
}

.dots {
  top: 315px;
  right: -140px;
  z-index: -1;
  overflow: hidden;
}

.popular-badge-2 {
  background-color: var(--accent-1);
  padding: 4px 8px;
  position: relative;
}

.continue {
  max-width: 150px;
  margin-top: 24px;
  margin-left: auto;
}

.payg-balance {
  font-size: 12px !important;
  font-weight: 600 !important;
  white-space: pre;

  &--total-card {
    color: rgba(255, 255, 255, 0.7);
  }
}

.payg-balance-value {
  font-size: 12px !important;

  &--total-card {
    color: rgba(255, 255, 255, 0.7);
  }
}

.payg-total-card,
.payg-total-table {
  background-color: var(--interactive-hover);
}

.sales-button {
  border: 1px solid #ffffff;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: var(--interactive-hover);
    border-color: var(--interactive-hover);
    outline: 0;
  }
}

.suggestion-button {
  border: 1px solid #ffffff;
  border-radius: 50px;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  min-width: 160px;
  width: fit-content;

  &:hover {
    background-color: #ffffff;
    outline: 0;
    color: var(--text-primary);
  }
}

.payg-sub-view-card {
  position: relative;
  background-color: var(--interactive-background);
  padding: 30px 50px;
  margin-bottom: 30px;
  border-radius: 20px;
  max-width: 1260px;
  width: 100%;
  height: 260px;
  background-image: url("../../../assets/images/plans/payg-fingers.png");
  background-position: 90% 0;
  background-repeat: no-repeat;

  &--sub-version {
    background-color: var(--brand-background);
    background-image: url("../../../assets/images/plans/handshake.png");
    background-position: 85% 0;
    background-repeat: no-repeat;
  }

  &.suggest-pro-premium {
    height: 350px;
    background-position: 85% 50%;
    background: url("../../../assets/images/plans/piggybank.png"),
      linear-gradient(265.96deg, #8a50f7 1.34%, #520f9a 36.89%, #520f9a 96.15%);
    background-repeat: no-repeat;
    background-position: 70% 50%;
  }

  &.suggest-business {
    background-image: url("../../../assets/images/plans/business-card.png");
    background-size: cover;
    background-position: top;
    height: 350px;
  }

  &.suggest-custom {
    background-image: url("../../../assets/images/plans/ringing-phone.png");
    background-position: 95% 100%;
    background-color: var(--feedback-warning);
  }
}

.pricing-contact-card {
  position: relative;
  background-color: var(--interactive-background);
  padding: 30px 50px;
  margin-bottom: 30px;
  border-radius: 20px;
  max-width: 1260px;
  width: 100%;
  height: 260px;
  background-image: url("../../../assets/images/plans/hi-five.png");
  background-position: 15% 100%;
  background-repeat: no-repeat;
}

.view-card-content {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h3 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0;
  }

  &.suggest-custom {
    max-width: 705px;
    margin-bottom: 4rem;

    h3,
    p,
    span {
      color: white;
    }
  }
}

.list-price {
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul li {
    color: #ffffff;
  }

  span {
    color: #ffffff;
  }

  h3 {
    color: #ffffff;
    padding-right: 6rem;
  }
}

.contact-card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-width: 575px;
  float: right;

  h3 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0;
  }
}

.view-card-text,
.contact-card-text {
  color: var(--text-primary);
  line-height: 26px;
}

.view-card-image {
  position: absolute;
  right: 10%;
  top: 0;

  &.suggest-pro-premium-image {
    right: 275px;
    top: 45px;
  }

  &.suggest-custom-image {
    top: auto;
    bottom: 0;
    right: 15px;
  }
}

.plan-icon {
  width: 175px;
  height: 75px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  position: absolute;
  right: 50px;
  top: 30px;
}

.payg-input {
  border: 1px var(--accent-2) solid !important;
  border-radius: 10px !important;
  padding-left: 40px !important;
}

.stopwatchIcon {
  position: absolute;
  left: 15px;
}

.payg-label {
  font-size: 12px !important;
  display: block;
  text-align: right;
}

@media screen and (max-width: 991px) {
  .payg-sub-view-card {
    background-size: 275px;
    background-position: 95% 0;

    &.suggest-pro-premium {
      background: linear-gradient(
        265.96deg,
        #8a50f7 1.34%,
        #520f9a 36.89%,
        #520f9a 96.15%
      );
    }

    &.suggest-business {
      background: #000000;
    }

    &.suggest-custom {
      background-image: none;
    }
  }

  .pricing-contact-card {
    background-size: 200px;
    background-position: 90% 100%;
  }
  .contact-card-content {
    float: left;
  }
}

@media screen and (max-width: 850px) {
  .plan-icon {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .payg-sub-view-card {
    background-position: 95% -25%;
  }

  .pricing-contact-card {
    background-size: 200px;
    background-position: 90% 115%;
  }
}

@media screen and (max-width: 576px) {
  .payg-sub-view-card {
    background-image: none;
    padding: 30px 25px;

    &.suggest-pro-premium,
    &.suggest-business,
    &.suggest-custom {
      span,
      li,
      p {
        font-size: 14px;
      }
      h3 {
        font-size: 1.5rem;
      }
    }
  }

  .list-price {
    h3 {
      padding-right: 0;
    }
  }

  &--sub-version {
    background-image: none;
    padding: 30px 25px;
  }

  .pricing-contact-card {
    background-image: none;
    padding: 30px 25px;

    h3 {
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 500px) {
  .payg-sub-view-card {
    &.suggest-custom {
      height: 300px;
    }

    &.suggest-pro-premium {
      p {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 395px) {
  .pricing-contact-card {
    height: 290px;
  }

  .contact-card-content,
  .view-card-content {
    p,
    h3 {
      margin-bottom: 0.5rem;
    }
  }
}

//TOPUP
.total-card-topup {
  background-color: var(--primary-800);
  box-shadow: 0 10px 20px -3px rgba(16, 24, 40, 0.08),
    0px 4px 15px -4px rgba(16, 24, 40, 0.1);
  border-radius: 10px;

  h6 {
    color: white;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  .pay-btn {
    align-items: center;
    border-radius: 6px;
    margin-top: 24px;
  }
}

.topup-card {
  background: var(--neutral-100);
  border-radius: 8px;
  padding: 12px;
}

.total-topup-balance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 12px;
  margin-bottom: 12px;

  background: var(--primary-900);
  border-radius: 6px;

  &-text {
    margin: 0;
    color: white;
    font-weight: 500;
    font-size: 14px;
  }

  &-quantity {
    margin: 0;
    color: white;
    font-weight: 600;
    font-size: 14px;
  }
}

.topup-rollover {
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: white;
  }
}

.topup-total {
  display: flex;
  flex-direction: column;

  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
      color: white;
      font-weight: 500;
      font-size: 14px;
    }

    p:nth-child(2) {
      font-size: 18px;
      font-weight: 600;
    }

    &-grand-total {
      font-weight: 600;
      font-size: 20px;
      letter-spacing: -0.02em;
      color: white;
    }
  }
}
