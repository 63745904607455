.container {
  width: 100%;
  margin: 0 auto;

  &--with-bg {
    background-image: url(../../assets/images/onboarding/shapes.png);
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.cards-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}

.card {
  background: #ffffff;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  padding: 32px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-child {
    margin-right: 32px;
  }

  p {
    max-width: 256px;
  }
}

@media only screen and (max-width: 580px) {
  .card-container {
    flex-direction: column;
  }

  .card:first-child {
    margin-right: 0;
    margin-bottom: 32px;
  }
}

// Invitation
.add-button {
  display: block;
  width: 100%;
}

.invitation {
  padding: 10px 20px;
  background-color: var(--interactive-background);
  display: flex;
  align-items: center;
  color: var(--interactive);
  margin-top: 8px;

  &:first-child {
    margin-top: 0;
  }

  .icon {
    flex-shrink: 0;
  }

  .email {
    margin-left: 24px;
    flex-grow: 1;
  }

  .delete {
    margin-left: 24px;
    cursor: pointer;
    line-height: 1;
  }
}

.dropdown-menu {
  max-width: 20px;

  .dropdown-item {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
}

//Trial invitation component
.trial-invite-container {
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 18px;

  background: white;
  box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.1);
  border-radius: 10px;
}
