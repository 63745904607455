.wrapper {
  position: relative;
  display: grid;
  width: 100%;
  max-width: 1260px;
  margin-right: auto;
  margin-left: auto;
  grid-auto-columns: 1fr;
  grid-row-gap: 40px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  .plans-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: -20px;
  }

  .plans-setting {
    position: absolute;
    top: 10px;
    left: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    a {
      text-decoration: none;
      color: var(--text-primary);
      display: flex;
      align-items: center;
    }

    svg {
      margin-left: -8px;
    }
  }

  h2 {
    margin: 0;
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
    text-align: left;

    span:not(.old-price) {
      position: relative;
      top: -2px;
      font-size: 17px;
      line-height: 26px;
      font-weight: 500;
      color: var(--text-primary);
    }

    &.price {
      font-size: 34px;
      line-height: 34px;
      font-weight: 700;
      color: var(--accent-4);
      display: inline;
      margin-right: 5px;

      span {
        color: var(--text-primary);
        font-size: 16px;
      }

      .dollar-sign {
        position: relative;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.02em;
        top: -0.75em;
        color: var(--text-primary);
      }
    }

    span.old-price {
      text-decoration: line-through;
      color: var(--text-disabled);
      display: inline;
      font-size: 34px;
      line-height: 46px;
      font-weight: 700;
      opacity: 0.7;
    }
  }
}

h2 {
  margin: 0;
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  text-align: left;

  &.price {
    font-size: 34px;
    line-height: 34px;
    font-weight: 700;
    color: var(--accent-4);
    display: inline;
    margin-right: 5px;
  }
  span {
    color: var(--text-primary);
    font-size: 16px;
  }

  .dollar-sign {
    position: relative;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.02em;
    top: -0.75em;
    color: var(--text-primary);
  }
}

.container {
  display: flex;
  align-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  justify-content: flex-start;
  margin-top: -94px;
  margin-bottom: 48px;
}

.cards-container {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 20px;

  width: 100%;

  background-color: transparent;
  background-position: 50% 50%;
  background-repeat: repeat-x;
  background-size: auto;
}

.card-line {
  align-self: center;
  background-color: var(--border);
  height: 410px;
  top: 23px;
  position: relative;
}

.popular-badge {
  background-color: #f2e5ff;
  border-radius: 50px;
  bottom: auto;
  color: var(--accent-4);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.5px;
  padding: 8px 16px;
  position: absolute;
  right: 15px;
  text-align: center;
  top: 12px;
}

.card {
  align-content: space-between;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  padding: 30px 22px 12px;
  border-radius: 20px;
  position: relative;
  background-color: #f8f7f7;

  .top {
    display: grid;
    flex: 0 0 auto;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin-bottom: 90px;
    row-gap: 20px;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    background-color: #0000000d;
    padding: 12px 14px;
    border-radius: 12px;

    .box {
      align-items: center;
      column-gap: 0;
      display: grid;
      flex: 0 0 auto;
      grid-auto-columns: 1fr;
      grid-template-columns: 2fr 1fr 1fr;
    }
  }
}

.plan-name-box {
  align-items: center;
  column-gap: 12px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 46px 1fr;
  grid-template-rows: auto;
  line-height: 24px;

  .plan-icon {
    height: 56px;
    width: 56px;
    object-fit: scale-down;

    &-frame {
      padding: 10px;
      border-radius: 10px;
    }
  }

  .plan-name {
    display: flex;
    flex-direction: column;
    margin-left: 5px;

    .subly {
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
      color: var(--text-primary);
      margin-bottom: 2px;
    }
  }
}

.plan-list {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin-bottom: 10px;
  margin-top: 0;
  padding-left: 0;
  row-gap: 4px;

  .item-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    display: flex;
  }

  &.two-column {
    grid-template-columns: 1fr 1fr;
  }
}

.item {
  color: var(--text-primary);
  display: flex;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;

  .icon {
    color: var(--accent-2);
    margin-right: 10px;
    position: relative;
    top: 4px;
    width: 16px;
    height: 16px;
  }
}

.sales-section {
  display: flex;
  flex-direction: column;

  .description {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    font-style: italic;
    margin-bottom: 5px;
    color: var(--text-primary);
  }

  a {
    text-decoration: none;
  }

  .sales-button {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 42px;
    padding: 12px 28px;
    text-align: center;
    color: var(--text-primary);
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    &:active {
      outline: 0;
    }

    &:hover {
      background-color: var(--interactive-hover);
      border-color: var(--interactive-hover);
      outline: 0;
      color: white;
    }
  }
}

.limit {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.5px;
  text-align: left;
  align-self: flex-start;

  &--modal-version {
    @extend .limit;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02px;
  }

  .label {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.5px;

    &--modal-version {
      @extend .label;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.02px;
    }
  }
}

.button {
  background-color: transparent;
  border: 2px solid var(--interactive);
  border-radius: 5px;
  box-shadow: none;
  color: var(--interactive);
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  padding: 12px 28px;
  transition: all 0.2s;

  &:active {
    outline: 0;
  }

  &:hover {
    background-color: var(--interactive-hover);
    border-color: var(--interactive-hover);
    outline: 0;
    color: white;
  }
}

@media screen and (max-width: 991px) {
  .container {
    margin-top: -37px;
    align-items: center;
  }

  .cards-container {
    padding: 0;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr 1fr;
    border-radius: 0;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
  }

  .card-line {
    display: none;
  }

  .card {
    padding: 27px 20px;
  }

  h2:not(.old-price) {
    font-size: 26px;
    line-height: 36px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    .plans-setting {
      display: none;
    }
  }
  .cards-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 576px) {
  .plan-list {
    &.two-column {
      grid-template-columns: 1fr;
    }
  }
}
