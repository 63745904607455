.dropdown {
  display: inline-flex;
  flex-shrink: 0;
}

.dropdown-toggle::after {
  margin-left: 0.455em;
  vertical-align: 0.155em;
}

.dropdown-item.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.dropdown-header {
  display: none;
}

.dropdown-menu {
  margin-top: 6px;
}

.dropdown-menu-container.has-overflow {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.dropdown-menu-up {
  top: auto;
  bottom: 50px;
}

@media only screen and (max-width: 768px) {
  .dropdown-menu,
  .navbar-expand .navbar-nav .dropdown-menu {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100% !important;
    max-width: 100vw;
    padding: 0;

    border-top: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .dropdown-header {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #5e5e5e;
      margin: 0;
    }
  }

  .dropdown-menu-container {
    padding: 12px 0;
    min-width: auto;
  }
}
