.modal {
  width: 100%;
  max-width: 700px;
  overflow: auto;

  svg {
    max-width: 70px;
    height: auto;
  }

  .video {
    width: 100%;
    height: auto;
    margin: 16px 0;
  }
}

@media only screen and (max-width: 768px) {
  .modal {
    top: 25vh;
  }
}
