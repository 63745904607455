.credit-card-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 11px 4px;
  background-color: white;
  height: 35px;
  margin-bottom: 15px;
  border: 1px solid #e4e5e7;
  border-radius: 5px;
  box-shadow: none;
  line-height: 2.2;
  font-size: 14px;
  color: #8a8a8a;
  transition: all 0.2s ease-in;

  &.focused,
  &:hover {
    border-color: var(--interactive);
    border-right-width: 1px;
  }

  &.focused {
    box-shadow: 0 0 0 2px rgba(90, 51, 163, 0.2);
  }
}
