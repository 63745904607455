@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@500;700&display=swap");

#partner-auth-wrapper {
  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.15;
    color: black;
  }

  #aside {
    padding: 20px 0 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .hero {
      margin: 40px 0;

      .col,
      .stats {
        display: flex;
        align-items: center;
      }

      ul {
        margin-top: 1.2rem;
        padding: 0;
        list-style: none;
      }

      li {
        &:before {
          content: ">";
          font-weight: bold;
          margin-left: 0px;
          margin-right: 3px;
        }

        font-size: 14px;
        line-height: 1.15;
        color: black;
        margin-top: 15px;

        span {
          background-color: var(--brand);
          padding: 0.1rem;
          font-weight: bold;
        }
      }
    }

    .auth-container {
      min-height: 60vh;

      .auth-form {
        margin-top: 50px;
      }
    }

    .logo {
      height: 35px;
    }

    .with-partner {
      .logo {
        padding: 0 20px;
        border-left: 2px solid black;

        &:first-child {
          padding-left: 0;
          border-left: none;
        }

        &:last-of-type {
          padding-right: 0;
        }

        &.lsff {
          height: 55px;
          position: absolute;
          top: -10px;
        }
      }
    }

    .how-it-works {
      background-color: #f8f6f4;
      padding: 20px;
      justify-content: center;

      h2,
      h3 {
        width: 100%;
        text-align: center;
      }

      h3 {
        font-weight: bold;
        font-size: 18px;
        line-height: 1.15;
        color: var(--interactive-link);
      }

      .title {
        text-align: center;
        margin: 20px 0 40px;
      }

      .benefits {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        flex-direction: column;
        max-width: 900px;

        .benefit {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 8px;

          img {
            min-height: 100px;
          }

          p {
            font-weight: bold;
            text-align: center;
            color: var(--text-primary);
            margin-bottom: 5px;
          }

          p + p {
            font-weight: normal;
            color: var(--tertiary);
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .auth-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .auth-form {
      padding: 20px;
      max-width: 500px;

      .success {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          font-size: 16px;
          line-height: 1.15;
          color: black;
          text-align: center;
        }
      }

      .title {
        font-weight: bold;
      }
    }
  }

  .form-container {
    margin-top: 20px;
  }

  .cta-top-right {
    position: absolute;
    top: 30px;
    right: 40px;
    z-index: 5;
  }

  .cta-btn {
    border: 1px solid var(--interactive);
    color: var(--interactive);
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
    margin-left: 1rem;

    &:hover {
      background: var(--interactive);
      border: 1px solid var(--interactive);
      color: #fff;
      text-decoration: none;
    }
  }

  .password-rules {
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 0.6rem;
    justify-content: center;

    .rule {
      margin-right: 12px;
      line-height: 1.5715;
    }
  }

  .terms {
    font-size: 12px;

    a {
      font-size: inherit;
    }
  }
}

@media only screen and (min-width: 680px) {
  #partner-auth-wrapper {
    #aside {
      .how-it-works {
        .benefits {
          flex-direction: row;

          .benefit {
            width: 25%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  #partner-auth-wrapper {
    .title {
      font-size: 32px;
    }
    #aside {
      .hero {
        li {
          font-size: 16px;
        }
      }
    }
  }
}
