.modal {
  width: 100%;
  max-width: 600px;
  overflow: auto;
  top: 5vh;
}

.title {
  h6 {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0;
  }

  .divider {
    margin: 1.5em 0em 1.5em 0em;
  }

  &-desc {
    color: var(--text-secondary);
    font-size: 12px;
  }
}

.card {
  min-width: 250px;
  min-height: 300px;
  padding: 1.8rem 1.2rem 1rem 1.2rem;
  border: 1px solid var(--border);
  border-radius: 4px;
  margin-bottom: 10px;

  .price-desc-mobile {
    display: none;
  }

  .plan-list {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding-left: 0;
    row-gap: 2px;
    margin-bottom: 0px;

    &-title {
      color: var(--text-secondary);
      display: flex;
      font-size: 12px;
      line-height: 18px;
    }

    .item {
      color: var(--text-secondary);
      display: flex;
      font-size: 12px;
      line-height: 24px;

      .icon {
        color: var(--interactive-hover);
        margin-right: 10px;
        position: relative;
        top: 4px;
        width: 16px;
        height: 16px;
      }
    }
  }

  .plan-price {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .price {
      display: flex;
      flex-direction: column;
      .slash {
        svg {
          position: relative;
          bottom: -25px;
          z-index: 1;
        }
      }
    }

    .price-desc-desk {
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0.15px;
      text-transform: uppercase;
      color: var(--interactive);
      line-height: 12px;
    }
  }
}

@media only screen and (max-width: 468px) {
  .title {
    .divider {
      margin: 0em 0em 1em 0em;
    }
  }

  .card {
    min-width: 100%;
    min-height: 100%;

    .plan-name-box {
      .price-desc-mobile {
        display: block;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0.15px;
        text-transform: uppercase;
        color: var(--interactive);
      }
    }

    .display-plan-list {
      display: none;
    }

    .price-desc-desk {
      display: none;
    }

    .plan-price {
      justify-content: space-evenly;
    }
  }
}
