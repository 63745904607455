.modal {
  width: 100%;
  max-width: 512px;
  overflow: auto;
  top: 20vh;
  padding: 24px;
}

.box {
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      height: 115px;
      width: 200px;
      margin-bottom: 10px;
    }

    .header {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 8px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--neutral-700);
      margin-bottom: 0;
    }
  }

  .animation-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;

    .animation {
      height: 225px;
      width: 225px;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;

    .button {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0;
      padding: 8px 18px;
      width: 100%;
    }
  }
  .link {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
    max-width: 350px;
    width: 100%;
    align-self: flex-end;

    .button {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0;
      padding: 8px 18px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .modal {
    top: 20vh;
  }
}
