.file-item--hasLanguageDropdown {
  display: flex;
  align-items: center;
  gap: 10px;
}

.processingIcon {
  color: var(--tertiary);

  path:first-child {
    fill: var(--background);
  }
}

.success {
  color: var(--brand);

  path:first-child {
    fill: var(--brand-background);
  }
}

.warning {
  color: var(--feedback-warning);

  path:first-child {
    fill: var(--feedback-warning-background);
  }
}

.error {
  color: var(--accent-1);

  path:first-child {
    fill: var(--feedback-error-background);
  }
}

.successCloseIcon {
  path:last-child {
    fill: var(--brand);
  }
}

.warningCloseIcon {
  path:last-child {
    fill: var(--feedback-warning);
  }
}

.errorCloseIcon {
  path:last-child {
    fill: var(--accent-1);
  }
}

.closeIcon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  opacity: 1;
  margin-left: 12px;

  &:hover {
    opacity: 1 !important;
  }
}

.text-secondary {
  color: var(--text-secondary);
}

.tooltip {
  white-space: pre-line;
}
