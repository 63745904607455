.nav {
  a {
    color: var(--text-secondary);
    font-size: 14px;

    &:hover {
      color: var(--interactive);
      background-color: white;
    }

    &.active {
      color: var(--interactive);
      background-color: white;
      font-weight: 500;
      border-bottom: 2px solid var(--interactive);

      &:hover {
        background-color: white;
      }
    }
  }
}

.dropdown {
  width: 100%;
  margin-left: -12px;

  .dropdown-button {
    color: var(--interactive);
    padding: 6px 12px;
    border-radius: 4px;

    &:hover:not(.dropdown-button-disabled),
    &--open {
      color: var(--interactive-hover);
      background-color: var(--interactive-background);
    }
  }

  .dropdown-menu {
    a,
    p {
      margin: 0;
      color: var(--interactive);
    }

    span {
      color: inherit;
    }

    a:hover {
      text-decoration: none;
      color: var(--interactive-hover);
    }
  }
}
