.modal {
  width: 100%;
  max-width: 565px;
  padding: 24px;

  overflow: auto;
}

.button {
  min-width: 100px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.coupon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5em;
  border: 1px solid var(--border);
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 1em;

  h5 {
    margin-bottom: 0.5em;
  }

  span {
    color: var(--interactive);
    margin-bottom: 0;
    font-size: 14px;
  }

  .button {
    width: 100%;
  }
}

.downgrade-list {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  padding-left: 22px;
  row-gap: 2px;
}

.downgrade-quote {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2em 0;

  span {
    align-self: flex-end;
    color: var(--accent-2);
  }
}

.business {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .header {
    h5,
    p {
      margin-bottom: 8px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .image {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 3px;
    }
  }

  .features {
    display: flex;
    flex-direction: column;
    padding: 12px 20px;
    gap: 4px;

    background: var(--secondary-50);
    border-radius: 10px;

    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      color: var(--neutral-600);
    }

    &-list {
      color: var(--neutral-600);
      padding-left: 18px;
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .contact {
    font-weight: 400;
    font-size: 14px;
    margin: 6px 0;

    .link {
      color: var(--accent-2);
    }
  }
}

@media only screen and (max-width: 768px) {
  .modal {
    top: 5vh;
  }

  .downgrade-quote {
    margin: 1em 0;

    img {
      height: 65%;
      width: 65%;
    }

    span {
      font-size: 10px;
    }
  }

  .button {
    font-size: 12px;
  }
}
