.storage-container {
  position: relative;
}

.desktop-view {
  position: absolute;
  top: 0;
  left: 560px;
  width: 280px;
}

.mobile-view {
  display: none;
}

.balance {
  margin-bottom: 0;

  span {
    color: var(--neutral-900);
    letter-spacing: 0.15px;
    font-size: 14px;
    font-weight: 500;
  }

  span:nth-child(2) {
    font-weight: 600;
  }
}

.divider {
  background-color: var(--neutral-200);
  margin: 10px 0;
}

.button-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  margin-top: 12px;
}

.storage-text {
  color: var(--neutral-600);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.storage-icon {
  width: 20px;
  margin-right: 5px;
}

@media only screen and (max-width: 1024px) {
  .desktop-view {
    display: none;
  }

  .mobile-view {
    margin: 12px 0;
    display: block;
  }
}
