.container {
  width: 100%;

  h1 {
    margin-bottom: 60px;
  }

  .terms {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral-900);

    a {
      text-decoration-line: underline;
      color: var(--neutral-900);
    }
  }

  .header {
    h2 {
      color: var(--neutral-900);
      font-weight: 700;
      margin-bottom: 2px;
    }

    span {
      color: var(--neutral-900);
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 420px) {
  .container {
    margin-top: 15px;

    h1 {
      margin-bottom: 36px;
    }

    .btn-google {
      margin-bottom: 24px;
    }
  }
}

.webView {
  background: var(--feedback-warning);
  border-radius: 4px;
}
