$theme-colors: (
  "brand": #39efa5,
  "brand-background": #e2fdf2,
  "tertiary": #808080,
  "text-primary": #1e1e1e,
  "text-secondary": #5e5e5e,
  "text-tertiary": #808080,
  "text-disabled": #9e9e9e,
  "light": #f0f1fb,
  "gray": #f1f1f1,
  "dark-gray": #d9d9d9,
  "green": #058567,
  "border": #e0e0e0,
  "background": #f2f2f2,
  "interactive": #8a50f7,
  "interactive-hover": #520f9a,
  "interactive-link": #377546,
  "interactive-background": #f2e5ff,
  "interactive-background-secondary": #f9f6ff,
  "accent-1": #e2186f,
  "accent-2": #0472e7,
  "accent-3": #520f9a,
  "accent-4": #732ef5,
  "feedback-error": #de0000,
  "feedback-error-background": #fce6e6,
  "feedback-warning": #ffab00,
  "feedback-warning-background": #fff7e6,
  "feedback-success": #00af41,
  "feedback-success-background": #e6f7ec,
  //Subly-Design-System-3.0
  "neutral-50": #f9fafb,
  "neutral-100": #f3f4f6,
  "neutral-200": #e5e7eb,
  "neutral-300": #d1d5db,
  "neutral-400": #9ca3af,
  "neutral-500": #6b7280,
  "neutral-600": #4b5563,
  "neutral-700": #374151,
  "neutral-800": #1f2937,
  "neutral-900": #1e1e1e,
  "primary-50": #f3effb,
  "primary-100": #d5c6f1,
  "primary-200": #c3adeb,
  "primary-300": #b794fa,
  "primary-400": #9d6df8,
  "primary-500": #8a50f7,
  "primary-600": #691ff5,
  "primary-700": #510ad7,
  "primary-800": #3f08a6,
  "primary-900": #2c0575,
  "secondary-50": #f0fdf4,
  "secondary-100": #dcfce7,
  "secondary-200": #bbf7d0,
  "secondary-300": #86efac,
  "secondary-400": #4ade80,
  "secondary-500": #39efa5,
  "secondary-600": #12e28e,
  "secondary-700": #15803d,
  "secondary-800": #166534,
  "secondary-900": #14532d,
  "accent-1-50": #dbecff,
  "accent-1-300": #55a6fc,
  "accent-1-500": #0472e7,
  "accent-1-800": #155aa2,
  "accent-1-900": #003a77,
  "accent-2-100": #facce0,
  "accent-2-300": #f070a7,
  "accent-2-400": #f070a7,
  "accent-2-500": #e2186f,
  "accent-2-600": #b41358,
  "accent-2-700": #860e42,
  "success-500": #00af41,
  "warning-50": #fffbeb,
  "warning-100": #fef3c7,
  "warning-200": #fde68a,
  "warning-300": #fcd34d,
  "warning-400": #fbbf24,
  "warning-500": #ffab00,
  "warning-600": #d97706,
  "warning-700": #b45309,
  "warning-800": #92400e,
  "warning-900": #78350f,
  "destructive-50": #fef2f2,
  "destructive-100": #fee2e2,
  "destructive-200": #fecaca,
  "destructive-300": #fca5a5,
  "destructive-400": #f87171,
  "destructive-500": #ef4444,
  "destructive-600": #dc2626,
  "destructive-700": #b91c1c,
  "destructive-800": #991b1b,
  "destructive-900": #7f1d1d,
  "tertiary-50": #e5f2ff,
  "tertiary-100": #b9dafe,
  "tertiary-400": #228bfb,
  "tertiary-500": #0472e7,
  "tertiary-700": #0056b3,
  "tertiary-900": #003166
);

$enable-shadows: true;

// Fonts
$font-family-sans-serif: Inter, -apple-system, system-ui, BlinkMacSystemFont,
  "Segoe UI", "Helvetica Neue", Arial, sans-serif;

// Spinner
$spinner-border-width-sm: 0.05em;

// Dropdown
$dropdown-border-radius: 4px;
$dropdown-box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2),
  0px 1px 4px rgba(0, 0, 0, 0.05);
$dropdown-link-color: var(--interactive);
$dropdown-link-hover-color: #383c72;
$dropdown-link-hover-bg: rgba(102, 111, 213, 0.1);
$dropdown-item-padding-y: 0.4rem;
$dropdown-item-padding-x: 1.4rem;
$dropdown-border-width: 0;

// Button
$btn-padding-y: 0.315rem;

// Nav
$nav-link-disabled-color: white;
$nav-link-padding-y: 0.315rem;

// Inputs
$label-margin-bottom: 4px;
$input-color: var(--text-primary);
$input-border-width: 1px;
$input-border-color: var(--tertiary);
$input-border-radius: 4px;
$input-placeholder-color: var(--tertiary);
$input-focus-border-color: var(--interactive);
$input-box-shadow: none;
$input-focus-box-shadow: none;

// Input Addons
$input-group-addon-bg: white;
$input-group-addon-color: var(--tertiary);

// Links
$link-color: var(--interactive-link);
$link-hover-color: $link-color;

// Toggle
$custom-control-indicator-checked-border-color: var(--interactive);
$custom-control-indicator-checked-bg: var(--interactive);

// Progress bar
$progress-bar-bg: var(--interactive);

// Top banners
:root {
  --top-banner-height: 0px;
}

div.has-top-bar {
  --top-banner-height: 36px;
}
