.card {
  padding: 24px;
  background-color: #ffffff;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  margin-bottom: 16px;

  h5 {
    position: relative;
    margin: 0;
  }

  .edit-icon {
    cursor: pointer;
    color: var(--interactive);
  }
}

.container {
  display: grid !important;
  grid-template-columns: 1fr 1.5fr;
  grid-column-gap: 30px;
  max-width: 1240px;
  margin-right: auto;
  width: 100%;
  margin-left: auto;
}

.wrapper {
  position: relative;
  display: grid;
  width: 100%;
  max-width: 1260px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 48px;
  grid-auto-columns: 1fr;
  grid-row-gap: 30px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}
.payg-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 85px;
  justify-content: space-between;
}

.payg-title {
  text-align: center;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.payg-icon {
  height: 56px;
  width: 56px;
  background-color: var(--brand);
  object-fit: scale-down;
  border-radius: 10px;
  margin-right: 5px;
}

.payg-subly {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: var(--text-primary);
  margin-bottom: 2px;
}

.payg-tagline {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border);
}

.plans-setting {
  position: absolute;
  top: 10px;
  left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  a {
    text-decoration: none;
    color: var(--text-primary);
    display: flex;
    align-items: center;
  }

  svg {
    margin-left: -8px;
  }
}

.table-name-box {
  align-items: center;
  grid-column-gap: 12px;
  column-gap: 12px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 46px 1fr;
  grid-template-rows: auto;
  line-height: 24px;
  margin-bottom: 1rem;
}

.table-name {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.minutes {
  border-bottom: 1px solid var(--border);
  padding-bottom: 1rem;
  margin-top: 3rem;
}

.cost-box {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
}

.cost {
  display: flex;
  align-items: flex-end;
}

.payg-input {
  border: 1px var(--accent-2) solid !important;
  border-radius: 10px !important;
  padding-left: 40px !important;
}

.stopwatchIcon {
  position: absolute;
  left: 15px;
}

.payg-label {
  font-size: 12px !important;
  display: block;
  text-align: right;
}
@media screen and (max-width: 991px) {
  .container {
    grid-template-columns: 1fr;
  }
  .plans-setting {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .features-wrapper {
    max-height: 260px;
    overflow: scroll;
  }
}
