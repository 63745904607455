.promo-banner {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  z-index: 1;
  background: var(--interactive-background);

  height: var(--top-banner-height);
  min-height: var(--top-banner-height);
  padding: 6px;

  a {
    color: var(--text-primary);
    text-decoration: underline;
    font-size: inherit;

    &:hover,
    &:focus,
    &:active {
      color: var(--text-primary);
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  &.bg-pink {
    background: var(--accent-1);
  }

  &.bg-warning {
    background: var(--feedback-warning);
  }

  &.bg-error {
    background: var(--feedback-error-background);
  }

  &.bg-feedback {
    background: var(--interactive-background);
  }
}

.primary-banner {
  display: flex;
  align-items: center;
  justify-content: left;

  width: 100%;
  height: 44px;
  padding: 12px 34px;
  z-index: 1;
  background: var(--primary-50);

  .text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: var(--primary-700);
    margin: 0;
  }
  .link {
    color: var(--primary-700);
    text-decoration: underline;
  }
}

.smile-icon {
  height: 16px;
  width: 16px;
  margin-right: 10px;
  color: var(--primary-700);
}

@media only screen and (max-width: 767px) {
  .promo-banner {
    height: auto;
  }
}
