.badge {
  background: var(--interactive-hover);
  border-radius: 100px;
  color: white;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;

  padding: 1px 4px;

  overflow: hidden;
  white-space: nowrap;

  flex-shrink: 0;

  &--premium {
    background: var(--accent-1);
  }

  &--link {
    cursor: pointer;
  }

  &--inverted {
    background-color: white;
    color: var(--interactive-hover);
    font-weight: bold;
  }

  &--plan {
    background-color: var(--background);
    color: var(--text-secondary);
    font-weight: normal;
    border-radius: 4px;
  }

  &--new-feature {
    background-color: var(--brand);
    color: var(--text-primary);
    font-weight: 500;
    // A bit more spacing from the designs because of the emoji
    padding: 4px 7px 3px;
  }
}

.loading {
  width: 100px;
}
