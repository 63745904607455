.tooltip {
  padding: 8px 10px !important;
  background: var(--neutral-700) !important;
  border-radius: 5px !important;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 18px;
  letter-spacing: 0.25px;
  opacity: 1 !important;
  max-width: 350px;
  white-space: pre-wrap;

  &:after {
    border-bottom-color: var(--neutral-700) !important;
  }
}

.parent {
  display: flex;
  align-items: center;
  justify-content: center;
}
