.trial-onboarding-container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  // Illustration
  background-color: var(--primary-50);
  background-image: url("../../assets/images/onboarding/welcome-trial-illustration.svg");
  background-position: 90% 90%;
  background-repeat: no-repeat;
  background-size: auto;
  background-size: 525px 370px;
}

.invite-box {
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: 450px;

  .skip-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .link {
      font-weight: 500;
      font-size: 14px;
      color: black;
    }
  }
}

@media only screen and (max-width: 947px) {
  .trial-onboarding-container {
    background-size: 416px 265px;
  }
}

@media only screen and (max-width: 425px) {
  .trial-onboarding-container {
    background-size: 272px 185px;
  }
}
