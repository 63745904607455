.custom-control {
  min-height: auto;
  line-height: 1;
  display: flex !important;

  label {
    display: flex;
    align-items: center;
  }
}

.input-label {
  cursor: pointer;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.15px;
  text-align: left;

  &::after,
  &::before {
    top: 0;
  }
}

.custom-checkbox-inverted {
  padding-left: 0 !important;

  label {
    width: 100%;

    &:after {
      height: 15px;
      width: 15px;
    }
    &:after,
    &:before {
      left: auto;
      right: 0;
    }
  }
}

.disabled {
  .input-label {
    cursor: not-allowed;
  }
}
