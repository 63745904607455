.cardContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  .grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 20px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.25em;
  border: 1px solid transparent;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    border-color: var(--border);
  }

  &--selected,
  &--selected:hover {
    border-color: var(--interactive);
  }

  .title {
    margin-bottom: 0px;
  }
}

.header {
  text-align: center;
  margin-bottom: 40px;
}

.animation {
  width: 175px;
  height: 175px;
}

@media only screen and (min-width: 490px) {
  .cardContainer .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 810px) {
  .cardContainer .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
