.modal {
  height: 550px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  display: flex;
  justify-content: space-between;
}

.icon-badge-container {
  display: flex;
  justify-content: space-between;
}

.current-plan-badge {
  padding: 4px 12px;
  background-color: rgba(243, 244, 246, 1);
  height: 30px;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-secondary);
}

.plan-box {
  width: 350px;
  background-color: rgba(242, 229, 255, 0.4);
  border-radius: 15px;
  padding: 24px;
}

.download-box {
  width: 350px;
  border-radius: 15px;
  padding: 24px;
  border: var(--border) 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payg-input {
  border: 1px var(--accent-2) solid !important;
  border-radius: 10px !important;
  padding-left: 40px !important;
}

.stopwatchIcon {
  position: absolute;
  left: 15px;
}

.payg-label {
  font-size: 12px !important;
  display: block;
  text-align: right;
  margin-bottom: 32px;
}

.dollar-sign {
  font-weight: 500;
  font-size: 16px;
}
.price {
  font-weight: 700;
  margin-top: 32px;
}

.features-list {
  ul li {
    font-weight: 500;
    font-size: 14px;
    color: var(--text-secondary);
  }
}

.cta-button {
  align-self: flex-end;
  max-width: 350px;
  width: 100%;
}

.download-button {
  border-radius: 4px;
  margin-top: auto;
}

.toggle-container {
  max-width: 150px;
  margin-bottom: 32px;

  &.oom-version {
    display: none;
  }
}

.pricing-toggle {
  border: none;
  border-radius: 4px;
  margin-right: 1rem;
  button {
    background-color: transparent;
    color: var(--text-secondary);
    border: var(--text-disabled) 1px solid !important;
    border-radius: 4px;
  }

  .selected {
    background-color: var(--interactive);
    color: white;
    border: var(--interactive-hover) 1px solid !important;
  }
}

.filename-box {
  max-height: 100px;
  overflow: auto;

  p {
    font-size: 12px;
    margin-bottom: 6px;
    white-space: nowrap;
  }
}
