// Based on Subly design system
// https://www.figma.com/file/p4ryBd6rT3VrIlm0NAusib/Subly-Design-System?node-id=1%3A17

h1 {
  font-size: 60px;
  line-height: 70px;
  font-weight: bold;
  color: var(--text-primary);
  letter-spacing: 0;
}

h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: normal;
  color: var(--text-primary);
  letter-spacing: 0;
}

h3 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 500;
  color: var(--text-primary);
  letter-spacing: 0;
}

h4 {
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  color: var(--text-primary);
  letter-spacing: 0;
}

h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--text-primary);
  letter-spacing: 0;
}

h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-primary);
  letter-spacing: 0;
}

// Body 1
p,
span,
label {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-secondary);
  letter-spacing: 0;

  // Body 2
  &.small {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.15px;
  }

  // Body 3
  &.smaller {
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.15px;
  }

  &.overline {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 2px;
  }

  &.large {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-align: left;
  }
}

th {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

td {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

a {
  font-weight: 500;

  &.smaller {
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.15px;
  }
}

label {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: var(--text-primary);
}

caption,
.caption {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: var(--text-secondary);
}

input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.form-control {
  border-color: var(--border);

  &:focus {
    border-width: 2px;
    padding: calc(0.375rem - 1px) calc(0.75rem - 1px);
    box-shadow: none !important;
  }

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--tertiary);
  }
}

.input-group {
  > .form-control,
  > .custom-select {
    &:not(:last-child) {
      border-right: none;
      padding-right: 0;

      & + .input-group-append > span {
        padding-left: 4px;
        padding-right: 12px;
      }

      &:focus + .input-group-append > span {
        border-color: var(--interactive);
        border-width: 2px;
        border-left-width: 0;
        padding: calc(0.375rem - 1px);
        padding-left: 3px;
        padding-right: 11px;
      }
    }
  }
}

input[type="range"] {
  border: none;
  border-radius: 8px;
  height: 7px;
  width: 100%;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
    background: var(--interactive);
  }

  &::-moz-range-thumb,
  &::-ms-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
    background: var(--interactive);
  }
}

.input-group-text {
  border-color: var(--border);
}
