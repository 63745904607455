@import "theme";
@import "subly";
@import "~bootstrap/scss/bootstrap";
@import "animate";
@import "toast";
@import "dropdown";
@import "~react-loading-skeleton/dist/skeleton.css";

.dashboard-full-height {
  min-height: calc(100vh - 150px - 94px);

  &.with-banner {
    min-height: calc(100vh - 150px - 94px - 52px);
  }
}
@media (max-width: 540px) {
  .dashboard-full-height.with-banner {
    min-height: calc(100vh - 150px - 94px - 80px);
  }
}

.full-height {
  min-height: calc(100vh - 58px);

  &.overflow-auto {
    max-height: calc(100vh - var(--top-banner-height));
  }
}

@media (hover: none) and (pointer: coarse) {
  .d-touch-flex {
    display: flex;
  }
  .d-mouse-flex {
    display: none;
  }
}

@media (hover: hover) and (pointer: fine) {
  .d-touch-flex {
    display: none;
  }
  .d-mouse-flex {
    display: flex;
  }
}

.no-wrap {
  white-space: nowrap;
}

.font-size-inherit {
  font-size: inherit;
}

.font-weight-semibold {
  font-weight: 600;
}

.underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.table {
  th {
    border-top: none;
  }

  td {
    vertical-align: middle;
  }
}

.max-w-prose {
  max-width: 65ch;
}

// Fix product tour from sliding up from the corner
div[id^="react-joyride-step-"] {
  div.__floater {
    transition: opacity 0.3s ease 0s !important;
  }
}

.text-editor-cue mark {
  background-color: var(--interactive-background);
  border-radius: 2px;

  font-size: 14px;
  line-height: 24px;
  color: var(--text-secondary);

  padding-left: 0;
  padding-right: 0;
}
