.divider {
  box-sizing: border-box;
  padding: 0;
  line-height: 1.5715;
  clear: both;
  width: 100%;
  min-width: 100%;
  display: flex;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;

  &:before,
  &:after {
    position: relative;
    top: 50%;
    width: 50%;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    transform: translateY(50%);
    content: "";
  }
}

.text {
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  display: inline-block;
  padding: 0 1em;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;
  color: var(--text-secondary);
}