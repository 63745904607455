.dropdown {
  width: 100%;
  position: relative;
  max-width: 200px;

  .dropdown-button {
    width: 100%;
    background: white;
    border: 1px solid var(--tertiary);
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dropdown-menu {
    overflow-x: hidden !important;
    position: absolute;
    width: 100%;
    max-height: 130px;
  }
  .dropdown-header {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  .dropdown {
    max-width: 150px;
    .dropdown-menu {
      bottom: auto !important;
      width: 200px !important;
    }
  }
}
