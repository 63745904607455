.progress {
  width: 100%;
  display: flex;
  height: 4px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: var(--interactive-background);
  border-radius: 0.25rem;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2), 0px 1px 4px rgba(0, 0, 0, 0.05);
}
