.folder-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin: 10px 0;
  width: 100vh;
  max-height: 80vh;
}
.icon {
  height: 20px;
  width: 20px;
  color: var(--neutral-700);
}
.plus-icon {
  width: 20px;
  height: 20px;
}
.tooltip {
  margin: -20px 0;
  padding: 8px 10px !important;
  background: var(--neutral-700) !important;
  border-radius: 5px !important;
  &:after {
    border-bottom-color: var(--neutral-700) !important;
  }
}
.folder-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 2px;
  gap: 4px;
  width: 370px;
  height: 42px;
  &:hover {
    cursor: pointer;
  }
  &--active {
    background-color: var(--neutral-100);
  }
  .folder-title-container {
    display: flex;
    align-items: center;
    p {
      padding-left: 4px;
      margin: 0px;
    }
    .folder-input {
      width: 300px;
      height: 30px;
      border: none;
      outline: none;
      padding: 0 5px;
      background-color: transparent;
      box-shadow: none;
    }
    .badge {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0px 8px;
      margin-left: 4px;
      gap: 8px;
      width: 24px;
      height: 20px;
      background: var(--neutral-200);
      border-radius: 24px;
      color: var(--neutral-700);
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }
    .pencil-icon {
      display: none;
    }
    &:hover .pencil-icon {
      display: inline-block;
      margin-left: 8px;
    }
  }
}
.folder-list {
  overflow-y: auto;
  max-height: 40vh;
}

@media only screen and (max-width: 768px) {
  .folder-list-container {
    padding: 0px;
    width: 100%;
    max-height: 80vh;
  }
  .folder-option {
    width: 100%;
  }
  .folder-list {
    overflow-y: scroll;
    max-height: 40vh;
    width: 100%;
  }
}
