.bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.45);
  z-index: 8;

  display: none;

  &--open {
    position: fixed;
    display: block;
  }
}

.sidebar {
  --sidebar-bg: var(--interactive-hover);
  --sidebar-active-bg: var(--interactive);
  --sidebar-text-color: white;
  --sidebar-workspace-color: var(--interactive);

  &.settings {
    --sidebar-bg: var(--brand-background);
    --sidebar-active-bg: var(--brand);
    --sidebar-text-color: var(--text-secondary);
    --sidebar-workspace-color: var(--interactive);
  }

  background-color: var(--sidebar-bg);

  flex-shrink: 0;
  width: 237px;
  min-height: 100vh;

  padding: 12px 0;
  padding-top: 46px;

  display: block;
  position: relative;

  &.with-banner {
    min-height: calc(100vh - var(--top-banner-height));
    padding-top: var(--top-banner-height);
  }

  .sidebar-container {
    // 50px for the workspaces button on the bottom
    max-height: calc(100vh - var(--top-banner-height) * 2 - 67px - 50px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@media only screen and (max-width: 1152px) {
  .sidebar {
    width: 195px;
  }
}

@media only screen and (max-width: 769px) {
  .sidebar {
    transform: translateX(-237px);
    width: 237px;
    position: fixed;
    top: 0;
    z-index: 10;
    animation: all;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    min-height: 100vh;
    height: 100vh;
    overflow: auto;

    &--open {
      transform: translateX(0px);
    }
  }
}

.sidebar-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  cursor: pointer;

  h6 {
    color: var(--sidebar-text-color);
    margin: 0;

    svg:first-child {
      margin-right: 12px;
    }
  }

  &--active {
    background-color: var(--sidebar-active-bg);
  }
}

a.sidebar-item {
  color: var(--sidebar-text-color);

  &:hover {
    text-decoration: none;
  }
}

.settings {
  .sidebar-item {
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;

    &--active {
      font-weight: 500;
    }
  }
}

.divider {
  margin: 6px 24px;
  border: 1px solid var(--sidebar-active-bg);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.add {
  width: 14px;
  background-color: var(--sidebar-active-bg);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    position: relative;
    top: -1px;
    color: var(--sidebar-text-color);
    font-size: 14px;
    line-height: 1;
    margin: 0;
    padding: 0;
  }
}

.sidebar-folder {
  display: flex;
  align-items: center;
  padding: 12px 24px 12px 36px;
  cursor: pointer;

  svg,
  span {
    color: var(--sidebar-text-color);
  }

  svg {
    margin-right: 6px;
  }

  &--active {
    background-color: var(--sidebar-active-bg);

    svg,
    span {
      color: var(--sidebar-text-color);
    }
  }

  &--input {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    border: none;
    outline: none;
    color: var(--sidebar-text-color);
    font-size: 12px;
    line-height: 21px;
    padding: 0 5px;
    margin-left: -5px;
  }
}
