.modal {
  top: 0;
  width: 100%;
  max-width: 512px;
  overflow: auto;
  border-radius: 10px;
}

.form {
  max-width: 612px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.alert {
  background-color: var(--background);
  padding: 16px;
  margin: 24px 0;
  border-radius: 4px;
  display: none;

  &--show {
    display: block;
  }
}

.icon {
  display: flex;
  justify-items: center;
  align-items: center;
  margin-bottom: 10px;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  h5 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 0px;
  }
}

.cancel-btn {
  color: var(--neutral-700);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-color: var(--neutral-300);
  border-radius: 6px;
}

.upload-btn {
  width: 204px;
  min-height: 36px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.option-btn {
  color: black;
  text-decoration-line: underline;
  cursor: pointer;

  &:hover {
    color: black;
    text-decoration-line: underline;
  }
}

.close {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
  height: 22px;
  width: 22px;
  fill: var(--text-secondary);
}

.video {
  width: 100%;
  height: 393px;
}

.totals {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  label {
    font-weight: 400;
    color: var(--text-primary);

    b {
      font-weight: 600;
    }
  }
}

.form {
  h6 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: black;
  }
}

@media only screen and (min-height: 900px) {
  .modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: initial;
  }
}

@media only screen and (max-width: 768px) {
  .modal {
    max-height: 100vh;
    overflow: auto;
  }

  .form {
    max-width: none !important;
  }

  .row {
    flex-direction: column;
  }

  .upload-zone {
    min-height: auto;
    margin-bottom: 24px;
  }

  .video {
    height: auto;
    max-height: 280px;
  }
}
