.tooltip {
  margin: -20px 0;
  padding: 8px 10px !important;
  background: var(--neutral-700) !important;
  border-radius: 5px !important;

  &:after {
    border-bottom-color: var(--neutral-700) !important;
  }
}

.plus-icon {
  width: 14px;
  height: 14px;
  &:hover {
    cursor: pointer;
  }
}
