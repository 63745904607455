.container {
  border: 1px solid var(--border);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 115px;
  position: relative;
  user-select: none;
  padding: 6px;

  font-size: 12px;
  font-weight: 700;
  letter-spacing: 3px;
  line-height: 16px;
  text-transform: uppercase;
}

@media screen and (max-width: 991px) {
  .container {
    margin-bottom: 75px;
  }
}

.tab {
  border-radius: 50px;
  color: var(--text-primary);
  cursor: pointer;
  padding: 9px 15px;

  &:active {
    outline: 0;
  }

  &:hover {
    outline: 0;
  }

  &:focus {
    outline: 0;
  }

  &--active {
    background-color: var(--accent-4);
    color: #fff;
  }
}

.price-discount {
  bottom: -52px;
  color: var(--text-primary);
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  letter-spacing: -1px;
  position: absolute;
  text-align: center;
  top: auto;
  white-space: nowrap;
  text-transform: none;
}
