.toolbar {
  background-color: var(--neutral-700);
  box-shadow: 0px -4px 6px -1px rgba(16, 24, 40, 0.1),
    0px -2px 20px -2px rgba(16, 24, 40, 0.05);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 30px;

  position: fixed;
  height: 54px;
  left: 237px;
  right: 0px;
  bottom: 0px;
  z-index: 10;

  &--show {
    animation: fadeup 300ms ease-out 0ms 1 forwards;
  }

  &--hide {
    animation: fadedown 300ms ease-out 0ms 1 both;
  }

  .toolbar-contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 32px;

    width: 340px;
    height: 30px;
    .selected-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;

      width: 92px;
      height: 20px;
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--light);
        width: 60px;
        height: 20px;
      }
      .badge {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0px 8px;
        gap: 8px;
        width: 24px;
        height: 20px;
        background: var(--neutral-600);
        border-radius: 24px;
        color: var(--light);
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
      }
    }
    .separator {
      background: var(--border) !important;
    }
    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 32px;

      width: 184px;
      height: 40px;
      .tooltip {
        margin: -20px 0;
        padding: 8px 10px !important;
        background: var(--neutral-700) !important;
        border-radius: 5px !important;
        &:after {
          border-bottom-color: var(--neutral-700) !important;
        }
      }
      .folderIcon {
        width: 20px;
        height: 20px;
        color: var(--light);
      }
      .delete-button {
        color: var(--light);
        &:hover svg,
        &:hover svg {
          color: var(--feedback-error);
        }
      }
    }
  }
  .close-button {
    font-size: 24px;

    color: var(--light);
  }
}
@keyframes fadeup {
  0% {
    transform: translateY(70px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadedown {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(70px);
    opacity: 0;
  }
}

@media only screen and (min-width: 770px) and (max-width: 1152px) {
  .toolbar {
    left: 195px;
  }
}
@media only screen and (max-width: 769px) {
  .toolbar {
    left: 0;
  }
}
@media only screen and (max-width: 458px) {
  .toolbar {
    max-width: 458px;
    .toolbar-contents {
      width: 300px;
      gap: 0;
      .selected-container {
        margin-right: 8px;
      }
      .actions {
        width: 100px;
        gap: 0;
        .button {
          padding: 8px 0px;
        }
      }
    }
  }
}
