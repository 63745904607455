.navbar {
  &.top-navbar {
    min-height: 59px;
  }

  .balance {
    align-self: center;
    letter-spacing: 0.15px;
    font-size: 14px;
    line-height: 21px;
    color: var(--text-primary);

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--text-primary);
    }
  }

  .navbar-back-arrow {
    margin-left: -0.4rem;
  }

  .low {
    align-self: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    background: var(--warning-50);

    padding: 4px 12px;
    border: 1px solid var(--warning-300);
    border-radius: 24px;
    margin-right: 15px;

    svg {
      color: var(--warning-500);
    }

    a {
      color: var(--warning-700);
    }
  }
}

.signup {
  li {
    font-size: 14px;
    line-height: 20px;
  }

  a,
  a:hover {
    color: var(--primary-600);
  }
}

@media only screen and (max-width: 769px) {
  .navbar {
    .balance {
      display: none;
    }

    .low {
      display: none;
    }
  }
}

@media (min-width: 576px) {
  .navbar {
    .balance {
      padding-right: 10px;
    }
  }
}
