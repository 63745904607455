.modal {
  top: 20vh;
  max-width: 512px;
  height: 280px;

  border: 0;
  background-image: url("../../../assets/images/dashboard-shortcuts/space-background.png");
  background-position: 15% 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }

  p {
    max-width: 360px;
    font-size: 14px;
    color: var(--neutral-700);
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
  }

  .cancel-button {
    background-color: transparent;
    border-color: white;
    color: white;
    margin-right: 20px;
  }
}
