.app-card {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral-200);
  border-radius: 10px;
  background-color: white;
  margin-bottom: 30px;
  cursor: pointer;
  transition: all 0.2s;
  overflow: hidden;

  min-height: 183px;
  padding: 20px;

  &:hover {
    border-color: var(--interactive);
  }
}

.suggestion {
  min-width: 540px;
  margin: 0px 15px 30px 15px;
  cursor: default;

  &:hover {
    border-color: var(--neutral-200);
  }
}

.name {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.info {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--neutral-900);
}

.app-input {
  border-color: var(--neutral-300);
}

.apps-button {
  width: 76px;
  height: 36px;
  color: var(--neutral-700);
  border-color: var(--neutral-300);
}
