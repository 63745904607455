.checkout-card {
  max-width: 100%;

  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 12px;

  background: var(--primary-800);

  border: 1px solid var(--primary-900);
  border-radius: 10px;

  h6 {
    color: white;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0;
  }

  &-summary {
    display: flex;
    flex-direction: column;
    padding: 10px;

    background: var(--primary-900);
    border-radius: 6px;

    &-text {
      color: white;
      font-weight: 600;
      font-size: 14px;
      margin: 0 0 12px;
    }

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      p {
        margin-bottom: 0;
        color: white;
        font-size: 14px;
        line-height: 20px;
      }
    }

    &-success {
      font-weight: 800;
      font-size: 18px;
      line-height: 28px;
      color: white;
      margin-bottom: 5px;
    }

    &-divider {
      background-color: var(--neutral-200);
      margin-top: 0;
    }
  }

  &-rollover {
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: white;
      margin-bottom: 0;
    }

    .last4 {
      font-weight: 600;
    }

    a {
      &:hover,
      &:focus,
      &:active {
        color: white;
      }
    }
  }

  &-total {
    display: flex;
    flex-direction: column;

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      p {
        margin: 0;
        color: white;
        font-weight: 500;
        font-size: 14px;
      }

      &-grand-total {
        font-weight: 600;
        font-size: 18px;
        color: white;
      }
    }
  }
}

.pay-btn {
  margin-top: 12px;
}

.balance {
  margin-bottom: 0;

  span {
    letter-spacing: 0.15px;
    font-size: 14px;
    color: white;
  }

  span:nth-child(2) {
    font-weight: 600;
  }
}

.success-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.toggle-container {
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 12px;

  margin-top: 12px;

  background: var(--neutral-50);
  border: 1px solid var(--neutral-100);
  border-radius: 10px;

  &-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral-900);
    margin-bottom: 0;
  }

  span {
    font-size: 12px;
    line-height: 20px;
    color: var(--neutral-700);
  }

  &-amount {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin: 10px 0;
  }

  &-toggle {
    cursor: pointer;
    &:hover {
      path:nth-child(1) {
        fill: var(--tertiary-100);
      }
    }
  }
}

.toggle-container-dark {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 12px;

  margin-top: 12px;

  background: var(--primary-800);
  border: 1px solid var(--primary-900);
  border-radius: 10px;

  &-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: white;
    margin-bottom: 0;
  }

  &-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
    margin: 10px 0;
  }

  &-quantity {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: white;
    margin-bottom: 0;
  }

  &-toggle {
    cursor: pointer;

    path:nth-child(1) {
      fill: var(--primary-700);
    }

    path:nth-child(2) {
      fill: white;
    }

    &:hover {
      path:nth-child(1) {
        fill: var(--primary-500);
      }
    }
  }

  .description {
    display: flex;
    flex-direction: row;
    padding: 10px;
    gap: 10px;

    background-color: var(--primary-900);
    border-radius: 6px;

    p {
      font-size: 12px;
      line-height: 20px;
      color: white;
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .checkout-card {
    max-width: 100%;

    padding: 12px;
  }
}
