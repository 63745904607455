.card {
  border: 1px solid var(--neutral-300);
  box-sizing: border-box;
  border-radius: 10px;
  max-width: 540px;
  min-width: 258px;

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: var(--neutral-900);
    letter-spacing: -0.02em;
  }
}
