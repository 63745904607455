.modal {
  top: 25vh;
  max-width: 450px;

  h5 {
    margin-bottom: 10px;
  }

  p {
    color: var(--neutral-900);
  }

  .contact-sales {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 10px;
    gap: 10px;

    background: var(--neutral-100);
    border-radius: 10px;

    p {
      color: var(--neutral-900);
      margin-bottom: 0;
    }

    a {
      background-color: transparent;
      color: var(--neutral-700);
    }

    .icon {
      margin-right: 8px;
      path {
        stroke: var(--neutral-600);
      }
    }
  }

  .cta-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 25px;
    column-gap: 12px;
  }
}

.stopwatchIcon {
  position: absolute;
  left: 15px;
}

.payg-input {
  border: 1px var(--accent-2) solid !important;
  border-radius: 10px !important;
  padding-left: 40px !important;
}

.payg-label {
  font-size: 12px !important;
  display: block;
  text-align: right;
  margin-bottom: 32px;

  &--cta-button-version {
    @extend .payg-label;
    margin-bottom: 0;
  }
}
