.title {
  font-weight: 700;
}

.icon {
  height: 24px;
  width: 24px;
}

.appMenu {
  display: flex;
}
