.table {
  th,
  td {
    text-align: center;
    padding: 16px;
    border-top: none !important;
  }

  thead > tr > th {
    border-bottom: none;
  }

  th:first-child,
  td:first-child {
    text-align: left;
    border-radius: 10px 0 0 10px;
  }

  td:last-child,
  th:last-child {
    border-radius: 0 10px 10px 0;
  }

  tr:nth-child(even) {
    background-color: var(--interactive-background-secondary);
  }
}

.container {
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 48px;
}
@media screen and (max-width: 768px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
