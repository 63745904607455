.wrapper {
  height: 60vh;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 40px;
}

.container {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  grid-column-start: 2;
  position: relative;

  .form {
    display: flex;
    flex-direction: column;

    div,
    input {
      max-width: 380px;
    }
  }
}

.image {
  display: none;
  flex-direction: column;
  align-items: center;
  grid-column-start: 1;
  margin-top: 100px;
  justify-self: flex-end;

  .animation {
    width: 300px;
    height: 300px;
  }

  svg {
    margin-bottom: 22px;
  }
}

.header {
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .wrapper {
    grid-template-columns: 0;
    justify-content: center;
    column-gap: 0px;
    max-width: 380px;
    margin: auto;
  }

  .header {
    width: auto;
  }
}

@media only screen and (min-width: 769px) {
  .container {
    .form {
      margin: 0;
    }
  }

  .image {
    display: flex;
  }
}
