.radio-label {
  font-weight: 500;
  font-size: 14px;
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;

  &::after,
  &::before {
    top: 0;
  }
}
