.modal {
  border-radius: 10px;

  .header {
    margin-bottom: 16px;
  }

  .close-icon {
    color: var(--neutral-700);
    top: 27px;
    right: 30px;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .cancel-button {
    margin-right: 6px;
  }
}
