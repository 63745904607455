.icon {
  margin-top: 3px;
  height: 20px;
  width: 20px;
  color: var(--text-secondary);
  &:hover {
    cursor: pointer;
  }
}
.badge {
  background: var(--brand);
  font-weight: 500;
  font-size: 9px;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  padding: 0;
  color: var(--text-secondary);
  position: absolute;
  top: 5px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  border-top: 1px solid black !important;
}

.green-bell {
  display: block;
  margin: 0 auto;
}

.notifications-menu {
  padding: 20px !important;
  right: 100px !important;
  width: 360px;
  &--empty {
    height: 300px;
  }
}

.heading {
  display: block;
  font-weight: 700;
  font-size: 12px;
  line-height: 21px;
  color: var(--text-primary);
}
.mark-all-button {
  &:hover {
    cursor: pointer;
  }
}

.no-notification-content {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.no-notification-message {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.notification-list {
  max-height: 250px;
  overflow-y: auto;
}

.notification-item {
  display: flex;
  gap: 10px;
  align-items: center;
  .logo {
    width: 30px;
    height: 30px;
  }
  .notification-message {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.15px;
    color: var(--text-primary);
    margin-bottom: 0;
  }
  .close-button svg {
    height: 30px;
    width: 30px;
    color: var(--text-tertiary);
  }
}

.date {
  font-size: 12px;
  line-height: 21px;
  color: var(--text-tertiary);
  padding-left: 40px;
}

.action-button {
  width: 160px;
  height: 36px;
  margin-left: 40px;
  margin-bottom: 20px;
}
