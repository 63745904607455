.editor-button {
  align-items: center;
  background: transparent;
  box-shadow: none;
  border: 0;
  display: flex;
  font-size: 24px;
  justify-content: center;
  outline: none;
  padding: 0;

  &:focus {
    outline: none;
  }

  > svg {
    fill: #333333;
  }
}

.selected {
  background-color: var(--interactive-background) !important;
}

.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  span {
    color: inherit;
  }

  &:active {
    color: white;
    background-color: var(--interactive-hover) !important;
  }

  &.is-dangerous {
    color: var(--feedback-error) !important;

    &:hover {
      background-color: var(--feedback-error-background) !important;
    }
  }
}
