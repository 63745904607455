.dropdown {
  display: flex;
  align-items: center;
  position: relative;

  .dropdown-button {
    background: var(--light);
    height: 20px;
    width: 20px;
    border-radius: 3px;
    align-items: center;
    display: flex;

    .kebab-menu-icon {
      padding: 2px 0;
      height: 20px;
      width: 20px;
    }
  }

  &--open {
    cursor: pointer;
  }

  .dropdown-menu {
    width: 100%;
    min-width: 180px;
    border-radius: 10px;
    margin-top: 50px;
    color: var(--neutral-900);
    position: absolute;
    top: -70px;
    left: -130px;
  }
  .heading {
    padding: 10px 24px 15px 24px;
    border-bottom: 1px solid #d1d5db;
    .title {
      margin-bottom: 0;
      color: var(--neutral-900);
      overflow-y: scroll;
    }
    margin-bottom: 10px;
    input {
      overflow-y: scroll;
      max-width: 200px;
      border: none;
      outline: none;
    }
  }
  .check-button {
    color: var(--success-500);
    width: 20px;
    height: 20px;
    margin-bottom: 8px;
  }
  .edit-button {
    margin-left: 6px;
    width: 20px;
    height: 20px;
    color: var(--neutral-600);
    cursor: pointer;
  }
  .close-button {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    height: 22px;
    width: 22px;
    color: var(--neutral-600);
  }
  .dropdown-item {
    color: var(--neutral-900);
    padding: 10px 24px;

    &:hover {
      background-color: var(--neutral-100);
    }
  }

  .dropdown-divider {
    border-color: var(--neutral-200);
    margin-left: 24px;
    margin-right: 24px;
  }
}
@media only screen and (max-width: 768px) {
  .dropdown {
    .dropdown-menu {
      position: fixed;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100% !important;
      max-width: 100vw;
      padding: 0;

      border-top: 1px solid #e0e0e0;
      box-sizing: border-box;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}
