.skeletonCard {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral-200);
  border-radius: 10px;
  background-color: white;
  margin-bottom: 30px;
  transition: all 0.2s;
  overflow: hidden;

  min-height: 183px;
  padding: 20px;

  margin: 0px 15px 30px 15px;
}
