.card.account-card {
  border: 1px solid var(--neutral-300);
  box-sizing: border-box;
  border-radius: 10px;
  max-width: 540px;
  min-width: 258px;

  .card-footer {
    background-color: white;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    a {
      margin-left: -4px;
    }
  }
}

.card-header {
  min-height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 2px;
  text-transform: uppercase;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  color: var(--text-primary);

  &.founding-members-header {
    background-color: var(--brand-background);
    letter-spacing: 2px;
    font-weight: 600;
  }

  .user-badge {
    width: 50px;
  }
}

.card-body {
  position: relative;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: var(--neutral-900);
  letter-spacing: -0.02em;
}

.profile-picture {
  cursor: pointer;
  height: 60px;
  width: 60px;

  .icon-holder,
  .loading {
    height: 60px;
    width: 60px;
    margin-top: -60px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    opacity: 0;
    transition: all 0.2s;
  }

  .icon-holder {
    br {
      display: none;
    }

    .icon-holder-text {
      display: none;
    }
  }

  &:hover .icon-holder {
    background-color: rgba(30, 30, 30, 0.6);
    opacity: 0.99; /*if we set to 1, a strange effect - the background opacity goes to 0*/
  }

  .loading {
    background-color: rgba(30, 30, 30, 0.6);
    opacity: 0.99;

    .spinner-border {
      margin-right: 0 !important;
    }
  }

  .letter-icon {
    font-size: 2em;
  }
}

.account-profile-card-actions {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 576px) {
  .card.account-card {
    .profile-picture {
      height: 100px;
      width: 100px;
    }

    .icon-holder,
    .loading {
      height: 100px !important;
      width: 100px !important;
      margin-top: -100px !important;
    }

    .icon-holder {
      .icon-holder-text {
        display: block !important;
        color: white;
      }
    }
  }
}
