.dropdown {
  width: 100%;

  .dropdown-button {
    width: 100%;
    background: white;
    border: 1px solid var(--tertiary);
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dropdown-menu {
    width: 100%;
    overflow-x: hidden !important;

    .folder-list {
      overflow-y: auto;
      max-height: 100px;
    }

    .dropdown-folder-header {
      display: flex;
      width: 100%;
      padding: 0.4rem 1.4rem;
      clear: both;
      color: black;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      justify-content: space-between;
      h6 {
        display: flex;
        margin-bottom: 0 !important;
        align-items: center;
        padding-left: 4px;
      }
    }
    .icon {
      height: 20px;
      width: 20px;
      margin-right: 2px;
      color: var(--neutral-700);
    }

    .chevron {
      cursor: pointer;
      &--isOpen {
        transform: rotate(180deg);
      }
    }

    .dropdown-folder-item {
      display: none;
      color: var(--neutral-900);
      &--isOpen {
        display: flex;
        font-size: 14px;
        line-height: 21px;
        padding-left: 48px;
        justify-content: flex-start;
        .pencil-icon {
          display: none;
        }
        &:hover .pencil-icon {
          display: inline-block;
          margin-left: 8px;
        }
      }
    }

    .new-folder-button {
      margin: 10px 30px;
    }
  }
}
