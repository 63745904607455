.modal {
  width: 100%;
  max-width: 1000px;
  overflow: auto;
  border-radius: 10px;
}
.close {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
  height: 22px;
  width: 22px;
  fill: var(--text-secondary);
}
.container {
  display: block;
}
.shortcuts {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px;
  gap: 12px;
}

.shortcut {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .name {
    color: var(--neutral-900);
    margin: 4px 0;
    transition: all 300ms;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: inline;
    margin-right: 5px;
  }

  &:hover .name {
    color: var(--primary-500);
  }
  .badges {
    display: inline;
    border-radius: 100px;
    color: white;

    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.25px;

    padding: 3px 6px;

    overflow: hidden;
    white-space: nowrap;

    flex-shrink: 0;
    background: var(--accent-1);
  }

  svg {
    border: 1px solid var(--neutral-200);
    border-radius: 12px;
  }
}

.feedbackForm {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 16px 24px;

  background-color: white;
  border: 1px solid var(--neutral-200);
  border-radius: 10px;

  width: 400px;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral-900);
    margin-bottom: 10px;
  }

  .formInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid var(--neutral-300);

    .input {
      outline: 0;
      border: 0;
      width: 350px;
      margin-bottom: 4px;
    }

    .send {
      cursor: pointer;
      margin-left: 5px;
      margin-bottom: 4px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .shortcuts {
    overflow-x: auto;
    flex-wrap: nowrap;
  }
}
