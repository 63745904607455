@import "~react-toastify/dist/ReactToastify.min.css";

.Toastify {
  .Toastify__toast-container {
    width: auto;
    min-width: 320px;

    &.Toastify__toast-container--top-center {
      top: 4em;
    }

    .Toastify__toast {
      box-sizing: border-box;
      border-radius: 4px;
      padding: 16px;
      min-height: auto;

      .Toastify__toast-body {
        display: flex;
        margin: 0;

        svg {
          margin-top: 1px;
        }
      }
    }
  }

  .Toastify__toast {
    font-size: 14px;
    line-height: 20px;
    color: var(--text-primary);

    &--success {
      background: var(--feedback-success-background);
      border: 1px solid var(--feedback-success);
    }

    &--error {
      background: var(--feedback-error-background);
      border: 1px solid var(--feedback-error);
    }

    &--warning {
      background: var(--feedback-warning-background);
      border: 1px solid var(--feedback-warning);
    }
  }
}

.Toastify__close-button {
  svg {
    margin-top: -20px;
    margin-right: -10px;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
  }
  &:focus {
    outline: none;
  }
  &--error {
    color: black;
  }
}
