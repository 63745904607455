.password-rules {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  .rule {
    display: inline-flex;
    font-size: 10px;
    display: flex;
    align-items: center;
    margin-right: 4px;
    color: var(--tertiary);
    margin-right: 20px;

    .icon {
      margin-right: 4px;

      &.error {
        color: var(--feedback-error);
      }

      &.success {
        color: var(--feedback-success);
      }

      &.warning {
        color: var(--feedback-warning);
      }
    }
  }
}
