.delete-account-card-actions {
  display: flex;
  justify-content: flex-start;
}

.delete-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--neutral-900);
}
