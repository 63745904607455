.container {
  margin: 120px auto 0;
  padding: 0 30px;
}

.background {
  position: fixed;
  z-index: -1;

  &.bottomRight {
    bottom: 0px;
    right: 0px;
  }

  &.topRight {
    top: 0px;
    right: 0px;
  }

  &.topLeft {
    top: 0px;
    left: 0px;
  }
}

@media screen and (max-width: 576px) {
  .bottomRight {
    bottom: -50px !important;
  }
  .topRight {
    top: -50px !important;
  }
  .topLeft {
    top: -150px !important;
  }
}
