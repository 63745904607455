.btn {
  border-radius: 4px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border: 1px solid transparent;
  padding: 8px 16px;
  text-align: center;
  min-width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  &:hover {
    font-style: normal;
    text-decoration: none;
  }
}

.btn-primary {
  background: var(--interactive);
  color: white;

  &:hover {
    background: var(--interactive-hover);
    color: white;
  }

  .disabled {
    background: var(--interactive);
  }
}

// TODO: Very similar to btn-border, clean up
.btn-primary-reverse {
  background: white;
  color: var(--interactive);
  border: 1px solid var(--interactive);

  &:hover {
    background: var(--interactive-hover);
    color: white;
  }

  .disabled {
    background: var(--interactive);
  }
}

.btn-secondary {
  background: white;
  border: 1px solid var(--tertiary);
  box-sizing: border-box;
  color: var(--text-secondary);

  &:hover {
    background: var(--gray);
    color: var(--text-secondary);
  }

  &.disabled {
    border: 1px solid var(--text-disabled);
    color: var(--text-disabled);
  }
}

.btn-tertiary {
  background: transparent;
  border: 1px solid transparent;
  box-sizing: border-box;
  color: var(--text-secondary);
  padding: 8px;

  &:hover {
    background: var(--background);
    color: var(--text-secondary);
  }

  &.disabled {
    color: var(--text-disabled);
  }
}

.btn-danger {
  background: var(--feedback-error-background);
  border: 1px solid var(--feedback-error);
  box-sizing: border-box;
  color: var(--feedback-error);

  &:hover {
    background: var(--feedback-error);
    color: white;
  }

  &.disabled {
    opacity: 0.7;
    color: var(--feedback-error);
  }
}

.btn-border {
  background: white;
  border: 2px solid var(--interactive);
  box-sizing: border-box;
  color: var(--interactive);
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;

  &:hover {
    background: var(--interactive-hover);
    border-color: var(--interactive-hover);
    color: white;
    text-decoration: none;
  }

  &.disabled {
    border: 1px solid var(--text-disabled);
    color: var(--text-disabled);
  }
}

.btn-borderless {
  box-sizing: border-box;
  color: var(--neutral-700);
  border-radius: 6px;

  &:hover {
    background: var(--neutral-100);
    text-decoration: none;
  }

  &.disabled {
    border: 1px solid var(--neutral-300);
    color: var(--text-disabled);
  }
}

.btn-border-secondary {
  background: transparent;
  box-sizing: border-box;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  border: 1px solid #000000;
  color: var(--text-primary);
  width: fit-content;

  &:hover {
    background: var(--interactive-hover);
    border-color: var(--interactive-hover);
    color: white;
    text-decoration: none;
  }
}

.toolbar-button {
  background: transparent;
  border: 0;
  display: inline-flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  outline: 0;
  padding: 8px 6px;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  color: var(--neutral-900);
  user-select: none;

  &:hover {
    background: var(--gray);
  }

  &:focus {
    border: 0;
    outline: 0;
  }

  &--active {
    background-color: var(--interactive-background);
    color: var(--interactive);

    &:hover {
      background: var(--interactive-background);
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.6;

  &:hover {
    background-color: inherit;
  }
}

.button-disabled {
  cursor: not-allowed;
  display: inline;
}

.btn-full {
  width: 100%;
}

.btnOutlined {
  border: 1px solid var(--neutral-300);
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 6px;
  color: var(--neutral-700);

  &:hover {
    background-color: var(--neutral-50);
    color: var(--neutral-700);
  }

  &:disabled {
    color: var(--neutral-300);
  }
}

.editor-toolbar {
  background: transparent;
  border: 0;
  color: var(--neutral-900);
  display: inline-flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  padding: 6px 8px;
  border-radius: 3px;
  margin: 0 8px;
  outline: 0;
  align-items: center;

  &:hover {
    background-color: var(--background);
  }

  &:focus {
    outline: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  &--active {
    background-color: var(--primary-50);
    color: var(--primary-600);
  }

  &--disabled {
    opacity: 0.3;

    &:hover {
      background-color: transparent;
      cursor: not-allowed;
    }
  }
}

// CSS hack to only work in iOS Safari
@supports (-webkit-hyphens: none) {
  .toolbar-button {
    position: relative;

    svg {
      position: absolute;

      & + * {
        padding-left: 1.2rem;
      }
    }
  }
}

.plans-button {
  background-color: var(--accent-4);
  border: 2px solid var(--accent-4);
  border-radius: 50px;
  box-shadow: none;
  color: white;
  cursor: pointer;
  display: inline-block;
  padding: 12px 10px;
  transition: all 0.2s;

  width: 130px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  &:active {
    outline: 0;
  }

  &:hover {
    background-color: var(--interactive-hover);
    border-color: var(--interactive-hover);
    outline: 0;
    color: white;
  }

  &.btn-secondary {
    border: 2px solid var(--tertiary);
    color: var(--text-secondary);

    &:hover {
      background: var(--gray);
      color: var(--text-secondary);
    }

    &.disabled {
      background-color: transparent;
      border: 2px solid var(--text-disabled);
      color: var(--text-disabled);
    }
  }

  &.payg-sub-view {
    width: fit-content;
  }
}

.cancelTrial {
  width: 100%;
  text-align: center;
  margin-top: 0.3rem;
  color: var(--text-primary);
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.15px;

  &:hover {
    color: var(--tertiary);
  }
}

@media only screen and (max-width: 767px) {
  .cancelTrial {
    display: block;
    margin-bottom: 0;
  }
}
