.upload-zone {
  width: 100%;
  max-width: 612px;
  padding: 32px;
}

.caption-link {
  font-weight: 400;
  text-decoration: underline;
}

.plansLink {
  text-decoration: underline;
}

.error {
  color: var(--accent-1);
}

.insufficient-credit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 12px;
  margin: 12px 0;
  border-radius: 10px;

  background-color: var(--tertiary-900);

  .text {
    p {
      font-size: 12px;
      line-height: 20px;
      color: white;
      margin: 0;

      &:first-child {
        font-weight: 600;
        margin: 0;
        font-size: 14px;
      }
    }
  }

  .button {
    background-color: transparent;
    color: white;
    border-color: white;
    border-radius: 6px;
  }
}
