.customerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--interactive-hover);
  box-shadow: 0px 25px 75px rgba(138, 80, 247, 0.3);

  .imageContainer {
    padding: 2rem 2rem 2rem 0;
  }

  .imageGrid {
    display: grid;
    grid-template-rows: 1fr;
  }

  .memberImage {
    border-radius: 100%;
    border: solid 8px rgba(147, 105, 225, 0.3);
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
    height: 200px;
    width: 200px;
    object-fit: cover;
  }

  .logoImage {
    border-radius: 100%;
    grid-column: 2;
    grid-row: 2;
    height: 86px;
    width: 86px;
    background-color: white;
    object-fit: contain;
  }
}

@media only screen and (max-width: 991px) {
  .customerContainer {
    flex-direction: row;

    .memberImage {
      width: 106px;
      height: 106px;
      border-width: 5px;
    }

    .logoImage {
      width: 50px;
      height: 50px;
    }

    .speechMark {
      width: 24px;
      height: 19px;
    }

    h4 {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .customerContainer {
    .memberImage {
      width: 96px;
      height: 96px;
      border-width: 5px;
    }

    .logoImage {
      width: 40px;
      height: 40px;
    }

    .imageContainer {
      padding: 1rem 1rem 1rem 0;
    }

    h4 {
      font-size: 16px;
      line-height: 24px;
    }

    span {
      font-size: 14px;
      line-height: 21px;

      & + span {
        font-size: 12px;
        line-height: 21px;
      }
    }
  }
}

@media only screen and (max-width: 424px) {
  .customerContainer {
    .memberImage {
      width: 64px;
      height: 64px;
      border-width: 4px;
    }

    .logoImage {
      width: 32px;
      height: 32px;
    }

    .speechMark {
      width: 18px;
      height: 14px;
    }

    h4 {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0;
    }

    span {
      font-size: 12px;
      line-height: 12px;

      & + span {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
}
