.title {
  font-weight: 700;
  text-decoration: none;

  // Add a bit of negative margin because the icon inside has some white space around
  margin-left: -0.5rem;

  &:hover {
    text-decoration: none;
  }
}

.zoom-tabs {
  display: flex;
  flex-shrink: 0;
  height: 48px;
  list-style: none;
  list-style-type: none;
  padding: 0 17px;
  margin: 0;
}

.zoom-tab {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 100%;
  line-height: 1;
  line-height: 17px;
  padding: 0 17px;
  border-bottom: solid 2px var(--neutral-300);
  min-width: auto;

  &--active {
    border-bottom: solid 2px var(--interactive-hover);
    color: var(--interactive-hover);
  }
}

.meetingCard {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral-200);
  border-radius: 10px;
  background-color: white;
  margin-bottom: 30px;
  transition: all 0.2s;
  overflow: hidden;

  min-height: 183px;
  padding: 20px;
}

.meetingHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  flex-grow: 0;

  .topic {
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  .meetingNumber {
    margin-left: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}

.tile {
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-50);
  padding: 12px 14px;
  border-radius: 12px;
  border: 1px solid var(--neutral-100);
  margin-bottom: 16px;

  .box {
    align-items: center;
    column-gap: 0;
    display: grid;
    flex: 0 0 auto;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .duration {
      order: 1;
    }
    .start {
      order: 2;
    }
    .end {
      order: 3;
    }
    .preview {
      order: 4;
    }

    @media only screen and (max-width: 960px) and (min-width: 521px) {
      grid-template-columns: 1fr 1fr;
      row-gap: 15px;

      .duration {
        grid-column: 1;
        grid-row: 1 / 2;
      }
      .start {
        grid-column: 1;
        grid-row: 2 / 2;
      }
      .end {
        grid-column: 2;
        grid-row: 2 / 2;
      }
      .preview {
        display: none;
      }
    }

    @media only screen and (max-width: 520px) {
      grid-template-columns: 1fr;
      row-gap: 15px;

      .duration {
        order: 2;
      }
      .start {
        order: 3;
      }
      .end {
        order: 4;
      }
      .preview {
        display: none;
      }
    }

    .limit {
      margin-right: 10px;

      .label {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--neutral-600);
      }

      .value {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--neutral-900);
      }

      .date {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--neutral-500);
      }
    }
  }
}

.preview-button {
  width: 124px;
  height: 36px;
  color: var(--neutral-700);
  border: 1px var(--neutral-300) solid !important;

  svg {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }
}

.type-badge {
  align-content: center;

  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.5px;
  padding: 6px 16px;
  text-align: center;
  margin-right: 8px;
  margin-bottom: 6px;

  background-color: var(--secondary-200);
  color: var(--success-700);

  &-audio {
    background-color: var(--primary-50);
    color: var(--primary-700);
  }

  svg {
    width: 16px;
    height: auto;
    margin-right: 6px;
  }
}

.mobile-preview-button {
  display: none;
  margin-left: auto;

  @media only screen and (max-width: 960px) {
    display: block;
  }
}

.buttonIcon {
  display: flex;
  justify-items: center;
  align-items: center;

  font-size: 16px;
  line-height: 24px;
  font-weight: 500;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
}

.badges {
  display: inline;
  border-radius: 100px;
  color: white;

  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.25px;

  padding: 3px 6px;
  margin-right: 16px;

  overflow: hidden;
  white-space: nowrap;

  flex-shrink: 0;
  background: var(--accent-1);
}
