.error {
  color: var(--accent-1);
}

.information-icon {
  height: 20px;
  width: 20px;
  color: var(--neutral-500);
  margin-left: 2px;
  cursor: pointer;
}
